import React from 'react'

export const mouths = [
	<>
		<path
			d='M25.178 25.622c-.845.445-4.378 1.467-7.178 1.467-3 0-6.508-1.022-7.353-1.467-.844-.444-1.776.344-1.51 1.867.363 2.074 1.253 4.684 3.387 4.195C13.224 31.524 15 30 18 30c3.022 0 4.645 1.524 5.345 1.684 2.133.489 2.893-2.122 3.255-4.195.267-1.523-.578-2.311-1.422-1.867z'
			fill='#292F33'
		/>
		<path
			d='M25.721 28.741l-.805-3c-1.155.485-4.343 1.348-6.917 1.348-2.835 0-6.12-.912-7.186-1.388l-.815 3.04a1.004 1.004 0 00.707 1.225 1.004 1.004 0 001.225-.707l.748-2.331c.892.344 3.114.664 5.322.66 2.161-.004 4.308-.324 5.18-.66l.616 2.331a.998.998 0 001.22.707 1 1 0 00.705-1.225z'
			fill='#fff'
		/>
		<path
			d='M22.929 29.906a.751.751 0 00-.578-.889.751.751 0 00-.889.578l-.119.643c-.351-.19-1.839-.646-3.343-.649-1.553-.003-3.125.454-3.484.649l-.198-.643c-.086-.404-.517-.664-.92-.578-.403.086-.679.486-.593.889l.318 1.514C14.038 30.941 15.632 30 18 30c2.255 0 3.729.847 4.624 1.345l.305-1.439z'
			fill='#fff'
		/>
	</>,
	<path
		d='M22.192 19.491c2.65 1.987 3.591 5.211 2.1 7.199-1.491 1.988-4.849 1.988-7.5 0-2.65-1.987-3.591-5.211-2.1-7.199 1.492-1.989 4.849-1.988 7.5 0z'
		fill='#292F33'
	/>,
	<path
		d='M14.293 29.707C14.425 29.84 15.653 31 18 31c2.347 0 3.575-1.16 3.707-1.294a.993.993 0 00.006-1.396 1.007 1.007 0 00-1.408-.029c-.032.03-.786.719-2.305.719-1.497 0-2.252-.67-2.303-.717a1 1 0 00-1.404 1.424z'
		fill='#292F33'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M18 25.747c5.297 0 6.243 3.683 6.282 3.848a.61.61 0 01-.275.665.567.567 0 01-.701-.097c-.014-.014-1.582-1.521-5.305-1.521s-5.291 1.506-5.306 1.522a.573.573 0 01-.703.092.6.6 0 01-.274-.661c.037-.158.991-3.848 6.282-3.848z'
				fill='#553986'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M9.447 24.895C9.201 24.402 9.45 24 10 24h18c.55 0 .799.402.553.895C28.553 24.895 26 30 19 30c-7 0-9.553-5.105-9.553-5.105z'
				fill='#292F33'
			/>
			<path
				d='M19 26c-2.771 0-5.157.922-6.292 2.256C14.2 29.211 16.253 30 19 30s4.801-.789 6.292-1.744C24.157 26.922 21.771 26 19 26z'
				fill='#F2ABBA'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<>
		<path
			d='M18 21c-3.623 0-6.027-.422-9-1-.679-.131-2 0-2 2 0 4 4.595 9 11 9 6.404 0 11-5 11-9 0-2-1.321-2.132-2-2-2.973.578-5.377 1-9 1z'
			fill='#664500'
		/>
		<path d='M9 22s3 1 9 1 9-1 9-1-2 4-9 4-9-4-9-4z' fill='#fff' />
	</>,
	<>
		<path
			d='M9 21c2.973.578 5.377 1 9 1s6.027-.422 9-1c.679-.132 2 0 2 2 0 4-4.596 9-11 9-6.405 0-11-5-11-9 0-2 1.321-2.131 2-2z'
			fill='#664500'
		/>
		<path d='M9 23s3 1 9 1 9-1 9-1-1.344 6.75-9 6.75S9 23 9 23z' fill='#fff' />
		<path
			d='M18 27.594c-3.596 0-6.272-.372-7.937-.745l-.825-1.871c.823.312 3.889.897 8.763.897 4.954 0 8.037-.616 8.864-.938l-.701 1.842c-1.634.38-4.419.815-8.164.815z'
			fill='#664500'
		/>
	</>,
	<>
		<path
			d='M29.969 22.243c-.005.02-.143.528-.466 1.277-.814 1.885-2.858 5.337-7.188 6.795C21.07 30.734 19.65 31 18 31c-9.669 0-11.948-8.67-11.97-8.758a1 1 0 011.94-.487C8.045 22.051 9.919 29 18 29s9.955-6.949 10.029-7.244a1 1 0 011.94.487z'
			fill='#664500'
		/>
		<path
			d='M33.175 27.309l-3.671-3.789c-.814 1.885-2.858 5.337-7.189 6.795L26 34c1.728 1.728 5 2 7 0s1.903-4.962.175-6.691z'
			fill='#E75A70'
		/>
	</>,
	<path
		d='M26.7 21.6a.501.501 0 01.729.657c-.128.215-3.217 5.243-9.429 5.243s-9.3-5.028-9.429-5.243A.5.5 0 019.3 21.6c.038.029 3.934 2.9 8.7 2.9 4.778 0 8.661-2.871 8.7-2.9z'
		fill='#664500'
	/>,
	<path
		d='M22.657 23.637a.513.513 0 01.597-.06.497.497 0 01.231.544C23.474 24.165 22.34 28.5 18 28.5s-5.474-4.335-5.484-4.379a.5.5 0 01.838-.475s1.005.854 4.646.854c3.644 0 4.647-.855 4.657-.863z'
		fill='#664500'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M26.7 25.6a.501.501 0 01.729.657c-.128.215-3.217 5.243-9.429 5.243s-9.3-5.028-9.429-5.243A.5.5 0 019.3 25.6c.038.029 3.934 2.9 8.7 2.9 4.778 0 8.661-2.871 8.7-2.9z'
				fill='#553986'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<>
		<path
			d='M18 22c-3.623 0-6.027-.422-9-1-.679-.131-2 0-2 2 0 4 4.595 9 11 9 6.404 0 11-5 11-9 0-2-1.321-2.132-2-2-2.973.578-5.377 1-9 1z'
			fill='#664500'
		/>
		<path d='M9 23s3 1 9 1 9-1 9-1-2 4-9 4-9-4-9-4z' fill='#fff' />
	</>,
	<path
		d='M18 21.849c-2.966 0-4.935-.346-7.369-.819-.557-.106-1.638 0-1.638 1.638 0 3.275 3.763 7.369 9.007 7.369s9.007-4.094 9.007-7.369c0-1.638-1.082-1.745-1.638-1.638-2.434.473-4.402.819-7.369.819z'
		fill='#664500'
	/>,
	<path
		d='M23.776 23.553a.5.5 0 01.698.605c-.386 1.157-1.817 3.342-4.475 3.342h-7.008a.513.513 0 01-.24-.066l-.001-.001A.5.5 0 0113 26.5c.049 0 4.905-.012 6.776-.947l4-2z'
		fill='#664500'
	/>,
	<path d='M11 26h14a1 1 0 100-2H11a1 1 0 100 2z' fill='#664500' />,
	<path
		d='M18 23.5c6.317 0 7.44 4.2 7.485 4.379a.5.5 0 01-.837.476C24.63 28.337 22.694 26.5 18 26.5s-6.629 1.837-6.648 1.856a.505.505 0 01-.6.077.499.499 0 01-.237-.554C10.56 27.7 11.682 23.5 18 23.5z'
		fill='#664500'
	/>,
	<path d='M18 24c-4 0-5 4-5 4s1-1 5-1 5 1 5 1-1-4-5-4z' fill='#664500' />,
	<path d='M14 28h8a1 1 0 100-2h-8a1 1 0 100 2z' fill='#664500' />,
	<path d='M12 28c2-5 13-5 13-3 0 1-8-1-13 3z' fill='#664500' />,
	<path
		d='M17.219 29.625a1 1 0 001.563-.001l3.193-3.992 2.226 2.967a1 1 0 00.729.397.975.975 0 00.778-.29l1-1a.999.999 0 10-1.414-1.414l-.185.185L22.8 23.4a.996.996 0 00-.784-.4c-.313 0-.604.134-.797.375L18 27.399l-3.22-4.024a1.002 1.002 0 00-1.58.025l-2.308 3.078-.185-.185a.999.999 0 10-1.414 1.414l1 1A.998.998 0 0011.8 28.6l2.225-2.967 3.194 3.992z'
		fill='#664500'
	/>,
	<path
		d='M20.871 28c1.335-.412 2.629-1.156 2.629-2.5 0-2.619-4.912-2.968-5.472-2.999a.493.493 0 00-.527.468.5.5 0 00.464.53c.035.002 3.535.299 3.535 2.001s-3.5 1.999-3.535 2.001c-.013.001-.023.008-.036.01a.446.446 0 00-.149.041l-.015.009a.476.476 0 00-.127.1c-.014.015-.023.031-.035.047a.496.496 0 00-.06.103c-.012.029-.018.061-.024.092-.004.023-.016.044-.018.067 0 .011.004.021.004.031 0 .01-.005.021-.004.031.001.024.013.045.018.068.006.031.011.061.023.09.013.03.031.057.049.084.017.024.032.05.052.071.023.023.05.041.078.061.024.017.046.034.074.047a.387.387 0 00.101.027c.024.006.044.018.069.02.035.001 3.535.298 3.535 2s-3.5 1.999-3.535 2.001A.501.501 0 0018 33.5l.028-.001c.56-.031 5.472-.38 5.472-2.999 0-1.344-1.294-2.088-2.629-2.5z'
		fill='#664500'
	/>,
	<>
		<path
			d='M7 21.262c0 3.964 4.596 9 11 9s11-5 11-9c0 0-10.333 2.756-22 0z'
			fill='#65471B'
		/>
		<path
			d='M18.545 23.604l-1.091-.005c-3.216-.074-5.454-.596-5.454-.596v6.961c0 3 2 6 6 6s6-3 6-6v-6.92a28.815 28.815 0 01-5.455.56z'
			fill='#E8596E'
		/>
		<path
			d='M18 31.843a.545.545 0 00.545-.545v-7.694l-1.091-.005v7.699a.546.546 0 00.546.545z'
			fill='#DD2F45'
		/>
	</>,
	<path
		d='M18 24.5c4.34 0 5.474 4.335 5.485 4.379a.5.5 0 01-.829.484C22.617 29.33 21.59 28.5 18 28.5c-3.644 0-4.648.855-4.657.863a.51.51 0 01-.596.06.496.496 0 01-.232-.544C12.526 28.835 13.66 24.5 18 24.5z'
		fill='#664500'
	/>,
	<path
		d='M18.244 24.5c4.272 0 7.092 6.034 7.21 6.291a.5.5 0 01-.796.575c-.026-.024-2.671-2.454-6.414-2.454-3.746 0-6.863 2.424-6.932 2.478l-.001.001a.496.496 0 01-.632-.008.5.5 0 01-.119-.621c.138-.255 3.435-6.262 7.684-6.262z'
		fill='#664500'
	/>,
	<path
		d='M25.2 26.6a.998.998 0 001.4.199.998.998 0 00.2-1.399c-.135-.179-3.362-4.4-8.8-4.4-5.438 0-8.665 4.22-8.8 4.4a.998.998 0 00.198 1.397 1.003 1.003 0 001.4-.194C10.826 26.566 13.583 23 18 23c4.418 0 7.175 3.566 7.2 3.6z'
		fill='#664500'
	/>,
	<>
		<path
			d='M9 29.965c2.973-.578 5.377-1 9-1s6.027.422 9 1c.679.132 2 0 2-2 0-4-4.596-9-11-9-6.405 0-11 5-11 9 0 2 1.321 2.131 2 2z'
			fill='#664500'
		/>
		<path d='M10 25s3-1 8-1 8 1 8 1-2-4-8-4-8 4-8 4z' fill='#fff' />
	</>,
	<>
		<path
			d='M7.504 29.938C7.515 29.854 8.667 21.5 18 21.5s10.485 8.354 10.496 8.437a.502.502 0 01-.71.515C27.72 30.422 23.578 28.5 18 28.5c-3.874 0-9.783 1.955-9.842 1.975a.5.5 0 01-.654-.537z'
			fill='#664500'
		/>
		<path d='M18 23c4 0 6 2 6 2H12s2-2 6-2z' fill='#CCD6DD' />
	</>,
	<>
		<path d='M25 21a4 4 0 010 8H11a4 4 0 010-8h14z' fill='#fff' />
		<path
			d='M25 20H11c-2.757 0-5 2.243-5 5s2.243 5 5 5h14c2.757 0 5-2.243 5-5s-2.243-5-5-5zm0 2a2.997 2.997 0 012.949 2.5H24.5V22h.5zm-1.5 0v2.5h-3V22h3zm-4 0v2.5h-3V22h3zm-4 0v2.5h-3V22h3zM11 22h.5v2.5H8.051A2.997 2.997 0 0111 22zm0 6a2.997 2.997 0 01-2.949-2.5H11.5V28H11zm1.5 0v-2.5h3V28h-3zm4 0v-2.5h3V28h-3zm4 0v-2.5h3V28h-3zm4.5 0h-.5v-2.5h3.449A2.997 2.997 0 0125 28z'
			fill='#664500'
		/>
	</>,
	<>
		<path
			d='M18 30c2.209 0 4-.237 4-3 0-2.761-1.791-6-4-6-2.21 0-4 3.239-4 6 0 2.763 1.79 3 4 3z'
			fill='#664500'
		/>
		<path
			d='M18 29c1.657 0 3-.895 3-2s-1.343-2-3-2-3 .895-3 2 1.343 2 3 2z'
			fill='#E75A70'
		/>
	</>,
	<path
		d='M18 30c2.21 0 4-2.239 4-5s-1.79-5-4-5-4 2.239-4 5 1.79 5 4 5z'
		fill='#664500'
	/>,
	<path
		d='M18 29c1.657 0 3-1.567 3-3.5S19.657 22 18 22s-3 1.567-3 3.5 1.343 3.5 3 3.5z'
		fill='#664500'
	/>,
	<path
		d='M18 18c-2.757 0-5 2.243-5 5v6c0 2.757 2.243 5 5 5s5-2.243 5-5v-6c0-2.757-2.243-5-5-5z'
		fill='#664500'
	/>,
	<>
		<path
			d='M18 33c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6z'
			fill='#664500'
		/>
		<path d='M18 23c-1.657 0-3 1.79-3 4h6c0-2.21-1.343-4-3-4z' fill='#F5F8FA' />
	</>,
	<path d='M18 29a3 3 0 100-6 3 3 0 000 6z' fill='#664500' />,
	<path
		d='M18 33c2.761 0 5-2.686 5-6s-2.239-6-5-6-5 2.686-5 6 2.239 6 5 6z'
		fill='#664500'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M28.023 24.191C27.046 24.383 23 26 18 26s-9.046-1.617-10.023-1.809C7 24 6.885 25.264 7.442 27.132 8 29 11 33 18 33s10-4 10.558-5.868c.557-1.868.442-3.132-.535-2.941z'
				fill='#292F33'
			/>
			<path
				d='M8 25s5 2 10 2 10-2 10-2-.5 3-1.5 3-1.5-1-1.5-1-4 2-7 2-7-2-7-2-.5 1-1.5 1S8 25 8 25z'
				fill='#F5F8FA'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M18 31.5c2.715 0 5.027-1.38 5.895-3.309.359-.798-.25-1.691-1.157-1.691h-9.476c-.907 0-1.516.893-1.157 1.691.868 1.929 3.18 3.309 5.895 3.309z'
		fill='#642116'
	/>,
	<>
		<path
			d='M10 27.004h15.001v-3L10 24.001c-1 0-1 1.003-1 1.003v1s0 1 1 1z'
			fill='#664500'
		/>
		<path
			d='M35.255 26.084l-7.713-2.121c-.72-.197-1.049.287-1.171.547l-1.64-.784L24 25.255v-2.254h-2v2h-2v-2h-2.001v2H16v-2h-2v2h-2v-2h-2v3h2v2h2v-2h1.999v2h2v-2H20v2h2v-2h1.643l-.58 1.212 1.648.788-.099.207s-.248.737.373 1.275c.621.537 5.285 4.735 5.285 4.735s.899.866 1.769.079c.738-.67 3.649-6.02 3.914-6.983.266-.964-.698-1.23-.698-1.23zm-3.772 6.071l-2.644-2.248 1.614-3.069 3.338 1.132-2.308 4.185z'
			fill='#99AAB5'
		/>
	</>,
	<>
		<path
			d='M7 21.263c0 3.964 4.596 9 11 9s11-5 11-9c0 0-10.333 2.756-22 0z'
			fill='#664500'
		/>
		<path
			d='M12 23.003v6.961c0 3 2 6 6 6s6-3 6-6v-6.92c-6.291 1.292-12-.041-12-.041z'
			fill='#5D9040'
		/>
		<path
			d='M20.97 31.069c0-1.368-1.379-1.901-2.487-2.329-.737-.284-1.434-.554-1.434-.943 0-.643.861-.675 1.034-.675.609 0 .912.229 1.18.43.205.155.418.315.713.315.55 0 .801-.388.801-.747 0-.717-.879-1.224-1.812-1.437V25.3a.917.917 0 00-1.832 0v.415c-1.115.324-1.799 1.145-1.799 2.179 0 1.278 1.261 1.803 2.373 2.265.797.331 1.549.644 1.549 1.132 0 .357-.448.743-1.173.743-.731 0-1.15-.292-1.52-.551-.255-.178-.497-.347-.785-.347-.419 0-.748.354-.748.804 0 .663.907 1.322 2.102 1.554v.459a.916.916 0 001.832 0l-.003-.486c1.263-.309 2.009-1.193 2.009-2.398z'
			fill='#fff'
		/>
	</>,
	<>
		<path
			d='M27.335 23.629a.501.501 0 00-.635-.029c-.039.029-3.922 2.9-8.7 2.9-4.766 0-8.662-2.871-8.7-2.9a.5.5 0 00-.729.657C8.7 24.472 11.788 29.5 18 29.5s9.301-5.028 9.429-5.243a.499.499 0 00-.094-.628z'
			fill='#664500'
		/>
		<path
			d='M18 26.591c-.148 0-.291-.011-.438-.016v4.516h.875v-4.517c-.145.005-.289.017-.437.017z'
			fill='#65471B'
		/>
		<path
			d='M22 26c.016-.004-1.45.378-2.446.486-.366.042-.737.076-1.117.089v4.517H20c1.1 0 2-.9 2-2V26zm-8 0c-.016-.004 1.45.378 2.446.486.366.042.737.076 1.117.089v4.517H16c-1.1 0-2-.9-2-2V26z'
			fill='#fff'
		/>
		<path
			d='M26.7 23.6a.501.501 0 01.73.657c-.097.162-1.885 3.067-5.429 4.481v-1.829c.016-.004-1.45.378-2.446.486-.366.043-.737.076-1.117.089-.147.005-.29.016-.438.016-.148 0-.291-.011-.437-.016-.38-.013-.751-.047-1.117-.089-.985-.107-2.43-.482-2.446-.486v1.829c-3.544-1.414-5.332-4.319-5.429-4.481A.499.499 0 019.3 23.6c.03.022 2.259 1.668 5.411 2.47.443.113 1.868.431 3.292.431 1.422-.001 2.843-.318 3.286-.431 3.135-.798 5.357-2.43 5.41-2.47h.001z'
			fill='#65471B'
		/>
	</>,
	<path
		d='M14.524 21.036a.914.914 0 01-.312-.464.799.799 0 01.59-1.021c4.528-1.021 7.577 1.363 7.706 1.465.384.306.459.845.173 1.205-.286.358-.828.401-1.211.097-.11-.084-2.523-1.923-6.182-1.098a.91.91 0 01-.764-.184z'
		fill='#65471B'
	/>,
	<>
		<path
			d='M29 25.5a2.5 2.5 0 01-2.5 2.5h-17a2.5 2.5 0 110-5h17a2.5 2.5 0 012.5 2.5z'
			fill='#F5F8FA'
		/>
		<path
			d='M17 23h2v5h-2v-5zm-5 0h2v5h-2v-5zm10 0h2v5h-2v-5zM7 25.5a2.5 2.5 0 002 2.45v-4.9a2.5 2.5 0 00-2 2.45zm20-2.45v4.899a2.5 2.5 0 000-4.899z'
			fill='#CCD6DD'
		/>
	</>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M25.861 16.129a.372.372 0 00-.535-.029c-.033.029-3.303 2.9-7.326 2.9-4.013 0-7.293-2.871-7.326-2.9a.374.374 0 00-.535.029.57.57 0 00-.079.628C10.168 16.972 12.769 22 18 22s7.833-5.028 7.94-5.243a.57.57 0 00-.079-.628z'
				fill='#664500'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M27.335 23.629a.501.501 0 00-.635-.029c-.039.029-3.922 2.9-8.7 2.9-4.766 0-8.662-2.871-8.7-2.9a.5.5 0 00-.729.657C8.7 24.472 11.788 31 18 31s9.301-6.528 9.429-6.743a.499.499 0 00-.094-.628z'
		fill='#DA2F47'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M13.849 23.827l1.515 2.295c1.413-.934 3.709-.938 5.134-.01L22 23.807c-1.167-.76-2.572-1.162-4.062-1.162-1.483 0-2.935.42-4.089 1.182z'
				fill='#292F33'
			/>
			<path
				d='M11.647 29.647a1.059 1.059 0 01-.336-2.063c.095-.033 2.453-.877 2.453-3.231s-2.358-3.198-2.458-3.232a1.062 1.062 0 01-.659-1.342c.187-.552.78-.852 1.334-.665.159.053 3.9 1.344 3.9 5.239 0 3.895-3.741 5.187-3.9 5.239-.11.037-.223.055-.334.055zm12.705 0c-.111 0-.223-.018-.334-.055-.159-.053-3.9-1.344-3.9-5.239 0-3.895 3.741-5.187 3.9-5.239a1.059 1.059 0 01.675 2.007c-.1.034-2.458.878-2.458 3.232s2.358 3.198 2.458 3.232a1.058 1.058 0 01-.341 2.062z'
				fill='#3E721D'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<>
		<path
			d='M18 25h4.5c.825 0 1.5.175 1.5 1s-1.175 2-2 2h-4.5c-.11 0-.217-.014-.321-.037C12.267 27.594 11 24 11 24s1.4 1 7 1z'
			fill='#664500'
		/>
		<path
			d='M23 26h-3c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1v3.5c0 .825.675 1.5 1.5 1.5s1.5-.675 1.5-1.5V27c0-.55-.45-1-1-1z'
			fill='#88C9F9'
		/>
	</>,
	<>
		<path
			d='M35.968 17.068l-4.005.813-16.187 10.508 7.118.963 11.685-7.211c.703-.431.994-.835 1.198-1.747.204-.912.191-3.326.191-3.326z'
			fill='#FFAC33'
		/>
		<path
			d='M23.485 29.379C23.474 29.335 22.34 26.5 18 26.5s-5.474 2.835-5.485 2.879a.496.496 0 00.232.544.51.51 0 00.596-.06C13.352 29.855 14.356 29 18 29c3.59 0 4.617.83 4.656.863a.5.5 0 00.829-.484z'
			fill='#664500'
		/>
		<path
			d='M35.474 15.729a2.502 2.502 0 00-3.501-.5L14.767 28.137c.646-.242 1.51-.444 2.615-.522.256-.018 2.66-.627 2.66-.627l1.293 1.036s.911.367 1.197.539l12.444-9.335a2.5 2.5 0 00.498-3.499z'
			fill='#CCD6DD'
		/>
		<path
			d='M28.686 20.87c-.448-.596-.787-1.145-1.383-.698l-9.922 7.443c.256-.018.5-.042.783-.044 1.36-.009 2.4.195 3.17.452l7.588-5.692c.596-.447.211-.864-.236-1.461z'
			fill='#DA2F47'
		/>
		<path
			d='M18.599 25.228l1.234 1.598.741-.595-1.197-1.587-.778.584zm2.223-1.661l1.235 1.598.741-.595-1.197-1.587-.779.584zm2.184-1.619l1.234 1.598.741-.595-1.197-1.587-.778.584zm2.181-1.66l1.235 1.597.741-.594-1.197-1.587-.779.584zm2.238-1.641l1.235 1.598.74-.595-1.196-1.587-.779.584zm2.14-1.618l1.235 1.598.74-.595-1.196-1.587-.779.584z'
			fill='#67757F'
		/>
		<path
			d='M22.531 28.563l.805.522s-.197-.362-.526-.726l-.279.204z'
			fill='#452E04'
		/>
	</>,
	<path d='M16 29c2-5 13-5 13-3 0 1-8-1-13 3z' fill='#664500' />,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M18.625 20.937c-3.543.759-5.981.85-9.01.908-.691.015-1.955.419-1.536 2.375.838 3.911 6.379 7.837 12.642 6.495 6.262-1.342 9.708-7.194 8.87-11.105-.419-1.956-1.739-1.808-2.375-1.536-2.786 1.187-5.048 2.104-8.591 2.863z'
				fill='#65471B'
			/>
			<path d='M11 24.004v6c0 3 2 6 6 6s6-3 6-6v-6H11z' fill='#E8596E' />
			<path
				d='M17 31.883a.545.545 0 00.545-.545v-6.295h-1.091v6.295a.546.546 0 00.546.545z'
				fill='#DD2F45'
			/>
			<path
				d='M10.034 23.801s3.143.349 9.01-.908c5.867-1.257 8.591-2.864 8.591-2.864s-1.117 4.33-7.962 5.797c-6.845 1.467-9.639-2.025-9.639-2.025z'
				fill='#fff'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M17.812 25.055c2.243 0 4.062-.864 4.062-1.93 0-1.066-1.819-1.93-4.062-1.93-2.243 0-4.062.864-4.062 1.93 0 1.066 1.819 1.93 4.062 1.93z'
		fill='#65471B'
	/>,
	<path
		d='M26.7 20.662a.501.501 0 01.729.657c-.128.215-3.217 5.243-9.429 5.243s-9.3-5.028-9.429-5.243a.5.5 0 01.729-.657c.038.029 3.934 2.9 8.7 2.9 4.778 0 8.661-2.871 8.7-2.9z'
		fill='#664500'
	/>,
	<path
		d='M18 27c3.866 0 7-3.358 7-7.5 0-4.142-3.134-7.5-7-7.5s-7 3.358-7 7.5c0 4.142 3.134 7.5 7 7.5z'
		fill='#664500'
	/>,
	<>
		<path
			d='M17.999 28.5c1.105 0 2-1.12 2-2.5s-.895-2.5-2-2.5-2 1.12-2 2.5.895 2.5 2 2.5z'
			fill='#664500'
		/>
		<path
			d='M13.346 31.273a.75.75 0 01-.722-.954c.437-1.54.258-3.029-.49-4.086-.497-.702-1.205-1.131-1.943-1.178a.75.75 0 01-.702-.795c.026-.413.381-.751.795-.701 1.193.074 2.313.733 3.073 1.807 1.011 1.429 1.27 3.383.709 5.361a.749.749 0 01-.72.546zm11.037-3.061a.744.744 0 01-.412-.124c-1.167-.77-1.82-2.117-1.792-3.695.029-1.635.809-3.153 1.984-3.869a.748.748 0 011.03.251.748.748 0 01-.251 1.03c-.735.448-1.244 1.499-1.264 2.614-.02 1.055.389 1.936 1.118 2.417a.75.75 0 01-.413 1.376z'
			fill='#E2A62D'
		/>
		<path
			d='M29.902 29.229l-10.573-1.303c-1.13-.102-3.117-.112-3.015-1.902.093-1.623 2.04-1.373 3.479-1.16l10.638 1.774-.529 2.591z'
			fill='#3B88C3'
		/>
		<path
			d='M30.43 26.639l-4.222-.724c-.494-.089-.934.647-.956 1.426-.025.866.227 1.304.726 1.406l4.144.512.308-2.62z'
			fill='#88C9F9'
		/>
		<path
			d='M34.918 26.341l-2.622 2.411-4.687-5.097 2.622-2.411a3.372 3.372 0 014.751.199l.135.147a3.373 3.373 0 01-.199 4.751z'
			fill='#3B88C3'
		/>
		<path
			d='M32.294 28.753c1.127-1.035.99-3.015-.304-4.423-1.294-1.407-3.256-1.71-4.382-.674s-.99 3.016.304 4.424c1.294 1.407 3.256 1.709 4.382.673z'
			fill='#88C9F9'
		/>
		<path
			d='M31.357 27.734c.563-.518.39-1.622-.386-2.467-.777-.844-1.863-1.109-2.426-.591-.563.518-.39 1.622.387 2.466.776.845 1.862 1.11 2.425.592z'
			fill='#269'
		/>
	</>,
	<path
		d='M23.186 29.526c-.993 0-1.952-.455-2.788-1.339-2.816-2.985-3.569-2.333-4.817-1.251-.781.679-1.754 1.523-3.205 1.523-2.351 0-3.969-2.302-4.036-2.4a1 1 0 011.644-1.14c.301.429 1.317 1.54 2.393 1.54.704 0 1.256-.479 1.895-1.033 1.816-1.578 3.764-2.655 7.583 1.388.823.873 1.452.774 1.908.592 1.659-.665 3.205-3.698 3.197-5.15a1 1 0 01.994-1.005h.006a1 1 0 011 .995c.012 2.103-1.854 5.976-4.454 7.017a3.601 3.601 0 01-1.32.263z'
		fill='#664500'
	/>,
	<>
		<path
			d='M26.041 29.251a.381.381 0 00.317-.071.375.375 0 00.142-.294c0-3.178-3.713-6.872-8.5-6.872s-8.5 3.694-8.5 6.872a.38.38 0 00.145.297.381.381 0 00.324.066c.076-.02 7.648-1.94 16.072.002z'
			fill='#292F33'
		/>
		<path
			d='M18.629 23.079v.827l-1.091.001v-.837c-2.277.127-4.191 1.162-5.455 2.516l.008.461c.086 2.932 2.08 5.802 5.992 5.802 3.899 0 5.892-2.851 5.991-5.773l.009-.284a8.057 8.057 0 00-5.454-2.713z'
			fill='#F4ABBA'
		/>
		<path
			d='M18 23.049c-.157 0-.308.014-.462.022v4.447a.545.545 0 101.09 0V23.08a7.61 7.61 0 00-.628-.031z'
			fill='#EA596E'
		/>
	</>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path d='M24.985 21.833a4 4 0 010 8h-14a4 4 0 010-8h14z' fill='#fff' />
			<path
				d='M24.985 20.812h-14c-2.757 0-5 2.243-5 5s2.243 5 5 5h14c2.757 0 5-2.243 5-5s-2.243-5-5-5zm0 2a2.997 2.997 0 012.949 2.5h-3.449v-2.5h.5zm-1.5 0v2.5h-3v-2.5h3zm-4 0v2.5h-3v-2.5h3zm-4 0v2.5h-3v-2.5h3zm-4.5 0h.5v2.5h-3.45a3 3 0 012.95-2.5zm0 6a2.997 2.997 0 01-2.949-2.5h3.449v2.5h-.5zm1.5 0v-2.5h3v2.5h-3zm4 0v-2.5h3v2.5h-3zm4 0v-2.5h3v2.5h-3zm4.5 0h-.5v-2.5h3.449a2.997 2.997 0 01-2.949 2.5z'
				fill='#1C6399'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M20.996 27c-.103 0-.206-.016-.309-.049a8.647 8.647 0 00-5.375 0 1 1 0 11-.617-1.902 10.633 10.633 0 016.609 0c.525.171.813.735.643 1.26a1.002 1.002 0 01-.951.691z'
		fill='#65471B'
	/>,
	<path
		d='M8.665 27.871a.501.501 0 00.635.029c.039-.029 3.922-2.9 8.7-2.9 4.766 0 8.662 2.871 8.7 2.9a.5.5 0 00.729-.657C27.3 27.029 24.212 22 18 22s-9.301 5.028-9.429 5.243a.499.499 0 00.094.628z'
		fill='#664500'
	/>,
	<>
		<path d='M18 25c-3 0-4-1-4 1s2 4 4 4 4-2 4-4-1-1-4-1z' fill='#662214' />
		<path d='M18 26h2v1s0 1-1 1-1-1-1-1v-1z' fill='#fff' />
	</>,
	<path d='M15 28c7 0 4 2 0 2s-7-2 0-2z' fill='#FFDC5D' />,
	<path
		d='M13.648 29.936c.078.028 1.932.715 4.352.715 2.421 0 4.274-.686 4.351-.715a.999.999 0 00.584-1.286.998.998 0 00-1.285-.587c-.015.006-1.609.588-3.65.588-2.04 0-3.634-.582-3.65-.588a1 1 0 00-.702 1.873z'
		fill='#DD2E44'
	/>,
]

function Mouth({ index, ...props }) {
	return mouths[index]
}

export default Mouth
