import React from 'react'

function BasesList({ bases, baseIndex, setBaseIndex }) {
	return (
		<>
			{bases.map((base, key) => (
				<li
					className={`${
						key === 0 ? `pr-2` : `px-2 border-l`
					} cursor-pointer border-gray-400`}
					key={key}
				>
					<svg
						className='md:w-10 md:h-10 sm:w-8 sm:h-8 w-6 h-6'
						viewBox='0 0 36 36'
						key={key}
						onClick={() => setBaseIndex(key)}
					>
						{base}
					</svg>
				</li>
			))}
		</>
	)
}

export default BasesList
