import React from "react"
import { saveAs } from "file-saver"
import { Link } from "gatsby"

import Header from "../components/Header.js"
import Footer from "../components/Footer.js"
import Seo from "../components/Seo"
import Emoji from "../components/Emoji"
import { bases } from "../components/Emoji/Base"
import { eyes } from "../components/Emoji/Eyes"
import { mouths } from "../components/Emoji/Mouth"
import { accessoriesList } from "../components/Emoji/Accessories"
import { RandomButton, ResetButton } from "../components/Emoji/Buttons"
import FacialsList from "../components/Emoji/FacialsList"
import BasesList from "../components/Emoji/BasesList"
import Modal from "../components/Emoji/Modal"

import ReactDOMServer from "react-dom/server"

function Index() {
	const [baseIndex, setBaseIndex] = React.useState(6)
	const [elsConfig, setElsConfig] = React.useState([
		{
			type: "eyes",
			index: 5,
			x: 0,
			y: 0,
			r: 0,
		},
		{
			type: "mouths",
			index: 12,
			x: 0,
			y: 0,
			r: 0,
		},
		{
			type: "accessories",
			index: 4,
			x: 0,
			y: 0,
			r: 0,
		},
	])
	const [selectedTab, setSelectedTab] = React.useState("basesList")
	const canvasRef = React.useRef(null)
	const [showModal, setShowModal] = React.useState(false)

	function triggerDownload(imageBlob, fileName) {
		saveAs(imageBlob, fileName)
	}

	function onDownloadSVG() {
		const svgEmoji = ReactDOMServer.renderToStaticMarkup(
			<Emoji
				width={160}
				height={160}
				baseIndex={baseIndex}
				elsConfig={elsConfig}
			/>
		)
		const svgBlob = new Blob([svgEmoji], { type: "image/svg+xml" })
		triggerDownload(svgBlob, "emoji-maker-jamstack-studio.svg")
	}

	function onDownloadPNG() {
		const svgEmoji = ReactDOMServer.renderToStaticMarkup(
			<Emoji
				width={160}
				height={160}
				baseIndex={baseIndex}
				elsConfig={elsConfig}
			/>
		)
		const canvas = canvasRef
		const ctx = canvas.current.getContext("2d")
		ctx.clearRect(0, 0, canvas.current.width, canvas.current.height)

		const DOMURL = window.URL || window.webkitURL || window
		const img = new Image()
		const svg = new Blob([svgEmoji], { type: "image/svg+xml" })
		const url = DOMURL.createObjectURL(svg)

		img.onload = () => {
			ctx.save()
			ctx.scale(2, 2)
			ctx.drawImage(img, 0, 0)
			ctx.restore()
			DOMURL.revokeObjectURL(url)
			canvasRef.current.toBlob(imageBlob => {
				triggerDownload(imageBlob, "emoji-maker-jamstack-studio.png")
			})
		}
		img.src = url
	}

	const lists = {
		eyesList: (
			<FacialsList
				{...{
					facials: eyes,
					facialName: "eyes",
					facialsConfig: elsConfig,
					setFacialsConfig: setElsConfig,
				}}
			/>
		),
		mouthsList: (
			<FacialsList
				{...{
					facials: mouths,
					facialName: "mouths",
					facialsConfig: elsConfig,
					setFacialsConfig: setElsConfig,
				}}
			/>
		),
		accessoriesList: (
			<FacialsList
				{...{
					facials: accessoriesList,
					facialName: "accessories",
					facialsConfig: elsConfig,
					setFacialsConfig: setElsConfig,
				}}
			/>
		),
		basesList: (
			<BasesList
				{...{
					bases,
					baseIndex,
					setBaseIndex,
				}}
			/>
		),
	}

	return (
		<div className="bg-white">
			<Seo
				pathname="/emoji"
				title="Emoji Maker - Design Custom Emojis for Free"
				description="Create your own Custom Emojis online for Free. Emoji Maker Tool helps you to design cool emojis online and save them as stickers in PNG and SVG Formats."
			/>
			<main>
				{showModal && (
					<Modal
						{...{ onDownloadPNG, onDownloadSVG, setShowModal, canvasRef }}
					/>
				)}
				<div className="mx-auto w-full bg-gray-300 border-b border-gray-300">
					<div className="mx-auto w-full justify-between h-screen flex flex-col">
						<div className="w-full bg-gray-800 flex flex-row justify-between text-white py-2 px-2 items-center mb-4">
							<div>
								<a
									className="font-semibold py-1 px-2 border border-white rounded hover:bg-white hover:border-transparent hover:text-gray-900"
									href="https://flat-icons.com/"
								>
									Flat Icons
								</a>
							</div>
							<h1 className="font-bold text-lg">
								<Link to="/">Emoji Maker</Link>
							</h1>
							<button
								className="font-semibold py-1 px-2 border border-white rounded hover:bg-white hover:border-transparent hover:text-gray-900"
								onClick={() => setShowModal(true)}
							>
								Export
							</button>
						</div>
						<div className="flex justify-center items-center w-full mx-auto">
							<Emoji
								width={160}
								height={160}
								className="lg:w-64 lg:h-64 md:w-56 md:h-56 sm:w-48 sm:h-48 w-40 h-40"
								baseIndex={baseIndex}
								elsConfig={elsConfig}
							/>
						</div>
						<div className="flex flex-col">
							<div className="border-b w-full bg-gray-100 text-black py-2 px-2 mt-4 border-gray-300">
								<ul className="flex flex-row justify-between items-center overflow-y-scroll scroll-auto">
									{lists[selectedTab]}
								</ul>
							</div>
							<div className="border-t w-full bg-white text-black py-2 px-2 mt-0 border-gray-100">
								<ul className="flex flex-row justify-center items-center">
									<li
										onClick={() => setSelectedTab("basesList")}
										className="pr-2 cursor-pointer"
									>
										<svg
											className="md:w-16 md:h-16 sm:w-12 sm:h-12 w-8 h-8"
											viewBox="0 0 36 36"
										>
											{bases[6]}
										</svg>
									</li>
									<li
										onClick={() => setSelectedTab("eyesList")}
										className="px-2 cursor-pointer border-l border-gray-400"
									>
										<svg
											className="md:w-16 md:h-16 sm:w-12 sm:h-12 w-8 h-8"
											viewBox="0 0 36 36"
										>
											<path
												d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
												className={`fill-current text-gray-400`}
											/>
											{eyes[5]}
										</svg>
									</li>
									<li
										onClick={() => setSelectedTab("mouthsList")}
										className="px-2 cursor-pointer border-l border-gray-400"
									>
										<svg
											className="md:w-16 md:h-16 sm:w-12 sm:h-12 w-8 h-8"
											viewBox="0 0 36 36"
										>
											<path
												d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
												className={`fill-current text-gray-400`}
											/>
											{mouths[12]}
										</svg>
									</li>
									<li
										onClick={() => setSelectedTab("accessoriesList")}
										className="px-2 cursor-pointer border-l border-gray-400"
									>
										<svg
											className="md:w-16 md:h-16 sm:w-12 sm:h-12 w-8 h-8"
											viewBox="0 0 36 36"
										>
											<path
												d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"
												className={`fill-current text-gray-400`}
											/>
											{accessoriesList[4]}
										</svg>
									</li>
									<li className="px-2 cursor-pointer border-l border-gray-400">
										<RandomButton
											{...{
												setBaseIndex,
												setElsConfig,
												basesLength: bases.length,
												eyesLength: eyes.length,
												mouthsLength: mouths.length,
												accessoriesLength: accessoriesList.length,
											}}
										/>
									</li>
									<li className="px-2 cursor-pointer border-l border-gray-400">
										<ResetButton
											{...{
												setBaseIndex,
												setElsConfig,
											}}
										/>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<h2 className="mt-2 mb-8 sm:mb-16 text-center">⚡ Design Custom Emojis for Free Using the Emoji Maker - Free</h2>
			</main>
			<Header isHome />
		</div>
	)
}

export default Index
