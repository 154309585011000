import React from 'react'

export const eyes = [
	<>
		<path
			d='M13.496 14.984l-2.38-.506a3.05 3.05 0 00-3.607 2.342 3.05 3.05 0 002.342 3.607l2.38.506a3.05 3.05 0 003.607-2.342 3.05 3.05 0 00-2.342-3.607zm9.008-.239l2.38-.506a3.05 3.05 0 013.607 2.342 3.05 3.05 0 01-2.342 3.607l-2.38.506a3.05 3.05 0 01-3.607-2.342 3.05 3.05 0 012.342-3.607z'
			fill='#55ACEE'
		/>
		<path
			d='M13.737 15.47l-1.956-.416a2.507 2.507 0 00-2.965 1.926 2.507 2.507 0 001.926 2.965l1.956.416a2.507 2.507 0 002.965-1.926 2.509 2.509 0 00-1.926-2.965z'
			fill='#FFCC4D'
		/>
		<path d='M12.739 19.208a1.5 1.5 0 100-3 1.5 1.5 0 000 3z' fill='#292F33' />
		<path
			d='M22.263 15.47l1.956-.416a2.507 2.507 0 012.965 1.926 2.507 2.507 0 01-1.925 2.965l-1.956.416a2.507 2.507 0 01-2.965-1.926 2.507 2.507 0 011.925-2.965z'
			fill='#FFCC4D'
		/>
		<path
			d='M23.261 19.208a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15 16c-4.254 0-7.422-2.08-7.555-2.168a1 1 0 011.108-1.666C8.581 12.185 11.372 14 15 14a1 1 0 010 2zm6 0a1 1 0 110-2c3.655 0 6.418-1.814 6.445-1.832a1 1 0 111.11 1.664C28.422 13.92 25.254 16 21 16z'
			fill='#292F33'
		/>
	</>,
	<>
		<path
			d='M13 14a2 2 0 100-4 2 2 0 000 4zM23 16a4 4 0 100-8 4 4 0 000 8z'
			fill='#292F33'
		/>
		<path d='M23 15a2 2 0 100-4 2 2 0 000 4z' fill='#9AAAB4' />
	</>,
	<path
		d='M13.503 14.845c3.124 3.124 4.39 6.923 2.828 8.485-1.562 1.562-5.361.297-8.485-2.828-3.125-3.124-4.391-6.923-2.828-8.485 1.563-1.562 5.361-.296 8.485 2.828zm8.994 0c-3.124 3.124-4.39 6.923-2.828 8.485 1.562 1.562 5.361.297 8.485-2.828 3.125-3.125 4.391-6.923 2.828-8.485-1.562-1.562-5.361-.297-8.485 2.828z'
		fill='#292F33'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M15.894 14.211l-6-3a2.001 2.001 0 00-1.789 3.579l4.212 2.106c-.306.533-.317 1.105-.317 1.105v2s0 2 2 2 2-2 2-2v-2s-.003-.109-.025-.263a1.98 1.98 0 00.814-.843 2.001 2.001 0 00-.895-2.684zm12.895-2.105a2 2 0 00-2.684-.895l-6 3a2 2 0 00-.895 2.684c.186.372.476.654.814.843-.021.154-.024.263-.024.263v2s0 2 2 2 2-2 2-2v-2s-.011-.572-.318-1.105l4.212-2.106a2 2 0 00.895-2.684z'
				fill='#553986'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M11 19a5 5 0 100-10 5 5 0 000 10zM25 19a5 5 0 100-10 5 5 0 000 10z'
		fill='#292F33'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M13.5 20c1.933 0 3.5-2.015 3.5-4.5S15.433 11 13.5 11 10 13.015 10 15.5s1.567 4.5 3.5 4.5zM23.5 20c1.933 0 3.5-2.015 3.5-4.5S25.433 11 23.5 11 20 13.015 20 15.5s1.567 4.5 3.5 4.5z'
				fill='#F5F8FA'
			/>
			<path
				d='M14 18c1.105 0 2-1.12 2-2.5s-.895-2.5-2-2.5-2 1.12-2 2.5.895 2.5 2 2.5zM23 18c1.105 0 2-1.12 2-2.5s-.895-2.5-2-2.5-2 1.12-2 2.5.895 2.5 2 2.5z'
				fill='#292F33'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M12 17c1.38 0 2.5-1.567 2.5-3.5S13.38 10 12 10s-2.5 1.567-2.5 3.5S10.62 17 12 17zM24 17c1.38 0 2.5-1.567 2.5-3.5S25.38 10 24 10s-2.5 1.567-2.5 3.5S22.62 17 24 17z'
		fill='#664500'
	/>,
	<path
		d='M16 18c-.419 0-.809-.265-.949-.684C14.848 16.717 14.034 15 13 15c-1.062 0-1.888 1.827-2.051 2.316a1 1 0 11-1.897-.633C9.177 16.307 10.356 13 13 13s3.823 3.307 3.949 3.684A1 1 0 0116 18zm10 0a.999.999 0 01-.948-.684C24.849 16.717 24.033 15 23 15c-1.062 0-1.889 1.827-2.052 2.316a1 1 0 01-1.897-.633C19.177 16.307 20.355 13 23 13c2.645 0 3.823 3.307 3.948 3.684A1 1 0 0126 18z'
		fill='#664500'
	/>,
	<path
		d='M28.457 17.797c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.503.503 0 00.755.605c.012-.009 1.262-.902 3.702-.902 2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604zm-12 0c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.499.499 0 00.754.605C8.31 18.393 9.559 17.5 12 17.5c2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604zM31 16c-.396 0-.772-.238-.929-.629-1.778-4.445-6.223-5.381-6.268-5.391a1 1 0 11.393-1.961c.226.045 5.556 1.168 7.732 6.608A1 1 0 0131 16zM5 16a1 1 0 01-.928-1.372c2.176-5.44 7.506-6.563 7.732-6.608a1 1 0 01.396 1.96c-.185.038-4.506.98-6.271 5.391A1.002 1.002 0 015 16z'
		fill='#664500'
	/>,
	<path
		d='M11.5 18c1.38 0 2.5-2.462 2.5-5.5S12.88 7 11.5 7 9 9.462 9 12.5s1.12 5.5 2.5 5.5zM24.5 18c1.38 0 2.5-2.462 2.5-5.5S25.88 7 24.5 7 22 9.462 22 12.5s1.12 5.5 2.5 5.5z'
		fill='#664500'
	/>,
	<path
		d='M28.457 17.797c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.503.503 0 00.755.605c.012-.009 1.262-.902 3.702-.902 2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604zm-12 0c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.499.499 0 00.754.605C8.31 18.393 9.559 17.5 12 17.5c2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604z'
		fill='#664500'
	/>,
	<path
		d='M28.457 17.797c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.503.503 0 00.755.605c.012-.009 1.262-.902 3.702-.902 2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604zm-12 0c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.499.499 0 00.754.605C8.31 18.393 9.559 17.5 12 17.5c2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604z'
		fill='#664500'
	/>,
	<path
		d='M30.6 18.2c-.114-.085-1.931-1.426-4.646-2.344.026-.115.046-.233.046-.356 0-.369-.139-.703-.359-.964A13.866 13.866 0 0129.002 14 1 1 0 0029 12c-.221 0-5.451.038-8.707 3.293A1 1 0 0021 17c4.59 0 8.363 2.772 8.401 2.801a1.003 1.003 0 001.4-.202A1 1 0 0030.6 18.2zm-14.893-2.907C12.452 12.038 7.221 12 7 12a1.001 1.001 0 00-.001 2c.026 0 1.558.016 3.361.536-.221.261-.36.595-.36.964 0 .123.019.241.047.356-2.716.918-4.533 2.259-4.647 2.344a.999.999 0 101.2 1.6c.037-.028 3.787-2.8 8.4-2.8a1.002 1.002 0 00.707-1.707z'
		fill='#664500'
	/>,
	<path
		d='M26 19a.999.999 0 01-.948-.684C24.849 17.717 24.033 16 23 16c-1.062 0-1.889 1.827-2.052 2.316a1 1 0 11-1.897-.633C19.177 17.307 20.355 14 23 14c2.645 0 3.823 3.307 3.948 3.684A1 1 0 0126 19zm-10 0c-.419 0-.809-.265-.949-.684C14.848 17.717 14.034 16 13 16c-1.062 0-1.888 1.827-2.051 2.316a.999.999 0 11-1.897-.633C9.177 17.307 10.355 14 13 14s3.823 3.307 3.949 3.684A1 1 0 0116 19z'
		fill='#664500'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M15.707 18.293C12.452 15.038 7.221 15 7 15a1 1 0 00-.001 2c.029 0 1.925.022 3.983.737-.593.64-.982 1.634-.982 2.763 0 1.934 1.119 3.5 2.5 3.5s2.5-1.566 2.5-3.5c0-.174-.019-.34-.037-.507.013 0 .025.007.037.007a.999.999 0 00.707-1.707zM29 15c-.221 0-5.451.038-8.707 3.293A.999.999 0 0021 20c.013 0 .024-.007.036-.007-.016.167-.036.333-.036.507 0 1.934 1.119 3.5 2.5 3.5s2.5-1.566 2.5-3.5c0-1.129-.389-2.123-.982-2.763A13.928 13.928 0 0129.002 17 1 1 0 0029 15z'
				fill='#553986'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM28.457 17.797c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.503.503 0 00.755.605c.012-.009 1.262-.902 3.702-.902 2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604zM5.999 12.458a1 1 0 01-.799-1.6c3.262-4.35 7.616-4.4 7.8-4.4a1 1 0 01.004 2c-.156.002-3.569.086-6.205 3.6a.995.995 0 01-.8.4zm23.002 2.125a.998.998 0 01-.801-.4c-2.592-3.457-6.961-2.627-7.004-2.62a1 1 0 01-.393-1.961c.231-.047 5.657-1.072 8.996 3.38a1 1 0 01-.798 1.601z'
		fill='#664500'
	/>,
	<path
		d='M7.999 15a1 1 0 01-.893-1.448C7.158 13.448 8.424 11 12 11c3.577 0 4.842 2.449 4.894 2.553a1 1 0 01-1.783.906C15.068 14.379 14.281 13 12 13c-2.317 0-3.099 1.433-3.106 1.447a.998.998 0 01-.895.553zm20.002 0a1 1 0 01-.896-.553C27.08 14.401 26.299 13 24 13s-3.08 1.401-3.112 1.46c-.26.481-.859.67-1.345.42a.994.994 0 01-.438-1.328C19.157 13.449 20.423 11 24 11c3.577 0 4.843 2.449 4.895 2.553A1 1 0 0128.001 15z'
		fill='#664500'
	/>,
	<path
		d='M6.999 17a1 1 0 01-.893-1.447C6.158 15.448 7.424 13 11 13c3.577 0 4.842 2.449 4.894 2.553a1.001 1.001 0 01-1.783.906C14.067 16.378 13.28 15 11 15c-2.317 0-3.099 1.433-3.106 1.447a.998.998 0 01-.895.553zM29 17a1 1 0 00.893-1.447C29.841 15.448 28.575 13 24.999 13c-3.577 0-4.842 2.449-4.894 2.553a1.001 1.001 0 001.783.906c.044-.081.831-1.459 3.111-1.459 2.317 0 3.099 1.433 3.106 1.447A.998.998 0 0029 17z'
		fill='#664500'
	/>,
	<path
		d='M28.312 15.612a.503.503 0 00-.61-.014c-.012.009-1.261.902-3.702.902-2.44 0-3.69-.893-3.7-.9a.5.5 0 00-.757.603c.06.135 1.5 3.297 4.457 3.297 2.958 0 4.397-3.162 4.457-3.297a.498.498 0 00-.145-.591zm-12.61-.014c-.012.009-1.26.902-3.702.902-2.441 0-3.69-.893-3.7-.9a.5.5 0 00-.757.603c.06.135 1.5 3.297 4.457 3.297 2.958 0 4.397-3.162 4.457-3.297a.501.501 0 00-.755-.605zM29.001 13a.998.998 0 01-.801-.4c-2.592-3.456-6.961-2.628-7.004-2.62a.998.998 0 01-1.177-.784 1.001 1.001 0 01.784-1.177c.231-.047 5.657-1.072 8.996 3.38A1 1 0 0129.001 13zM6.999 13a1 1 0 01-.799-1.6c3.339-4.454 8.766-3.426 8.996-3.38a1.001 1.001 0 01-.39 1.962C14.62 9.947 10.37 9.174 7.8 12.6a.998.998 0 01-.801.4z'
		fill='#664500'
	/>,
	<path
		d='M16.65 3.281a4.666 4.666 0 00-8.884.254 4.666 4.666 0 00-4.225-.58A4.67 4.67 0 00.692 8.911c.122.344.284.663.472.958 1.951 3.582 7.588 6.1 11.001 6.131 2.637-2.167 5.446-7.665 4.718-11.677a4.712 4.712 0 00-.233-1.042zm2.7 0a4.67 4.67 0 015.956-2.85 4.67 4.67 0 012.929 3.104 4.666 4.666 0 014.225-.58 4.671 4.671 0 012.85 5.956 4.72 4.72 0 01-.473.958c-1.951 3.582-7.588 6.1-11.002 6.131-2.637-2.167-5.445-7.665-4.717-11.677.037-.348.112-.698.232-1.042z'
		fill='#DD2E44'
	/>,
	<path
		d='M31.001 16a.998.998 0 01-.801-.4c-2.641-3.521-6.061-3.599-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0124 10c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6zM4.999 16a1 1 0 01-.799-1.6C7.462 10.05 11.816 10 12 10a1 1 0 01.004 2c-.156.002-3.569.086-6.205 3.6a.995.995 0 01-.8.4zm10.898 1.396c.023-.052.059-.096.073-.154a1 1 0 00-.727-1.213c-.18-.045-4.467-1.08-7.797 1.138a1 1 0 001.109 1.665c1.092-.729 2.362-.995 3.468-1.061-.009.076-.023.151-.023.229a2 2 0 004 0c0-.212-.042-.412-.103-.604zm11.999-.001c.023-.052.059-.095.073-.152a.999.999 0 00-.727-1.213c-.18-.045-4.466-1.08-7.797 1.138a1 1 0 101.11 1.664c1.092-.729 2.361-.994 3.469-1.06-.009.076-.024.15-.024.228a2 2 0 004 0 1.96 1.96 0 00-.104-.605z'
		fill='#664500'
	/>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM24.5 20c1.38 0 2.5-1.567 2.5-3.5S25.88 13 24.5 13 22 14.567 22 16.5s1.12 3.5 2.5 3.5z'
		fill='#664500'
	/>,
	<path
		d='M15 18H8a1 1 0 010-2h7a1 1 0 010 2zm13 0h-7a1 1 0 110-2h7a1 1 0 110 2z'
		fill='#664500'
	/>,
	<path
		d='M29.001 14a.998.998 0 01-.801-.4c-2.432-3.244-6.514-.846-6.686-.743a1 1 0 11-1.029-1.715c1.998-1.199 6.514-2.477 9.314 1.257A1 1 0 0129.001 14zM6.999 14a1 1 0 01-.799-1.6c2.801-3.734 7.317-2.456 9.314-1.257a1 1 0 01-1.027 1.716c-.179-.106-4.274-2.475-6.688.742a.997.997 0 01-.8.399zM29 16a1 1 0 00-1-1h-7a1 1 0 100 2h5.092a1.496 1.496 0 002.872-.821c.012-.06.036-.116.036-.179zm-13 0a1 1 0 00-1-1H8a1 1 0 000 2h5.092a1.496 1.496 0 002.872-.821c.011-.06.036-.116.036-.179z'
		fill='#664500'
	/>,
	<path
		d='M28.457 17.797c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.503.503 0 00.755.605c.012-.009 1.262-.902 3.702-.902 2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604zm-11 0c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.499.499 0 00.754.605c.013-.009 1.262-.902 3.703-.902 2.426 0 3.674.881 3.702.901a.5.5 0 00.755-.604z'
		fill='#664500'
	/>,
	<path
		d='M17.312 17.612a.501.501 0 00-.61-.014c-.012.009-1.26.902-3.702.902-2.441 0-3.69-.893-3.7-.9a.5.5 0 00-.757.603c.06.135 1.5 3.297 4.457 3.297 2.958 0 4.397-3.162 4.457-3.297a.498.498 0 00-.145-.591zm10 0a.5.5 0 00-.61-.014c-.012.009-1.261.902-3.702.902-2.44 0-3.69-.893-3.7-.9a.5.5 0 00-.757.603c.06.135 1.5 3.297 4.457 3.297 2.958 0 4.397-3.162 4.457-3.297a.498.498 0 00-.145-.591zM6 14a1 1 0 01-.004-2c.156-.002 3.569-.086 6.205-3.6a1 1 0 011.6 1.2C10.538 13.95 6.184 14 6 14zm24 0c-.184 0-4.537-.05-7.8-4.4a1 1 0 111.599-1.2c2.641 3.521 6.061 3.599 6.206 3.6.55.006.994.456.991 1.005A.996.996 0 0130 14z'
		fill='#664500'
	/>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM24.5 20c1.38 0 2.5-1.567 2.5-3.5S25.88 13 24.5 13 22 14.567 22 16.5s1.12 3.5 2.5 3.5z'
		fill='#664500'
	/>,
	<path
		d='M29 12c-5.554 0-7.802-4.367-7.895-4.553a1 1 0 011.787-.899C22.967 6.694 24.713 10 29 10a1 1 0 110 2zM7 12a1 1 0 010-2c5.083 0 5.996-3.12 6.033-3.253a1.007 1.007 0 011.219-.709c.53.139.851.673.718 1.205C14.921 7.437 13.704 12 7 12zM12 20c1.38 0 2.5-1.567 2.5-3.5S13.38 13 12 13s-2.5 1.567-2.5 3.5S10.62 20 12 20zM24 20c1.38 0 2.5-1.567 2.5-3.5S25.38 13 24 13s-2.5 1.567-2.5 3.5S22.62 20 24 20z'
		fill='#664500'
	/>,
	<path
		d='M11.226 15.512c-.317 0-.636.039-.947.116-2.87.707-3.513 4.121-3.539 4.267a.5.5 0 00.877.407c.01-.012 1.01-1.178 3.379-1.761a8.698 8.698 0 012.073-.265c1.104 0 1.732.253 1.735.254.067.028.131.04.207.04a.5.5 0 00.306-.902c-.367-.435-1.953-2.156-4.091-2.156zm13.548 0c.317 0 .636.039.947.116 2.87.707 3.513 4.121 3.539 4.267a.5.5 0 01-.877.407c-.01-.012-1.01-1.178-3.379-1.761a8.698 8.698 0 00-2.073-.265c-1.104 0-1.731.253-1.735.254a.511.511 0 01-.206.04.5.5 0 01-.306-.902c.366-.435 1.952-2.156 4.09-2.156z'
		fill='#664500'
	/>,
	<path
		d='M30.6 18.2c-.114-.085-1.931-1.426-4.646-2.344.026-.115.046-.233.046-.356 0-.369-.139-.703-.359-.964A13.866 13.866 0 0129.002 14 1 1 0 0029 12c-.221 0-5.451.038-8.707 3.293A1 1 0 0021 17c4.59 0 8.363 2.773 8.401 2.801a.999.999 0 001.4-.202A1 1 0 0030.6 18.2zm-14.893-2.907C12.452 12.038 7.221 12 7 12a1.001 1.001 0 00-.001 2c.026 0 1.558.016 3.361.536-.221.261-.36.595-.36.964 0 .123.019.241.047.356-2.716.918-4.533 2.259-4.647 2.344a1 1 0 001.2 1.6c.037-.028 3.787-2.8 8.4-2.8a1.002 1.002 0 00.707-1.707z'
		fill='#65471B'
	/>,
	<>
		<path
			d='M15.457 15.815c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.499.499 0 00.754.605c.013-.009 1.262-.902 3.703-.902 2.426 0 3.674.881 3.702.901a.496.496 0 00.609-.01.5.5 0 00.146-.594z'
			fill='#65471B'
		/>
		<path d='M31 13.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z' fill='#F4F7F9' />
		<path d='M24.5 16a2.5 2.5 0 100-5 2.5 2.5 0 000 5z' fill='#292F33' />
	</>,
	<path
		d='M11.5 18c1.38 0 2.5-1.567 2.5-3.5S12.88 11 11.5 11 9 12.567 9 14.5s1.12 3.5 2.5 3.5zM24.5 18c1.38 0 2.5-1.567 2.5-3.5S25.88 11 24.5 11 22 12.567 22 14.5s1.12 3.5 2.5 3.5z'
		fill='#65471B'
	/>,
	<path
		d='M28.416 17.723C28.355 17.632 26.901 15.5 24 15.5c-2.9 0-4.355 2.132-4.416 2.223a.499.499 0 00.071.638.504.504 0 00.643.042c.012-.01 1.262-.903 3.702-.903 2.426 0 3.674.881 3.702.901a.5.5 0 00.714-.678zM12 15.5c-2.9 0-4.355 2.132-4.416 2.223a.5.5 0 00.713.68c.013-.01 1.262-.903 3.703-.903 2.426 0 3.674.881 3.702.901a.5.5 0 00.714-.679c-.061-.09-1.515-2.222-4.416-2.222zm19.001.5a.998.998 0 01-.801-.4c-2.641-3.521-6.061-3.599-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0124 10c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6zM4.999 16a1 1 0 01-.799-1.6C7.462 10.05 11.816 10 12 10a1 1 0 01.004 2c-.156.002-3.569.086-6.205 3.6a.995.995 0 01-.8.4z'
		fill='#664500'
	/>,
	<path
		d='M16 19c-.419 0-.809-.265-.949-.684C14.848 17.717 14.034 16 13 16c-1.062 0-1.888 1.827-2.051 2.316a.999.999 0 11-1.897-.633C9.177 17.307 10.356 14 13 14s3.823 3.307 3.949 3.684A1 1 0 0116 19zm10 0a.999.999 0 01-.948-.684C24.849 17.717 24.033 16 23 16c-1.062 0-1.889 1.827-2.052 2.316a1 1 0 11-1.897-.633C19.177 17.307 20.355 14 23 14c2.645 0 3.823 3.307 3.948 3.684A1 1 0 0126 19z'
		fill='#664500'
	/>,
	<path
		d='M11.5 19c1.38 0 2.5-1.567 2.5-3.5S12.88 12 11.5 12 9 13.567 9 15.5s1.12 3.5 2.5 3.5zM28.457 17.797c-.06-.135-1.499-3.297-4.457-3.297-2.957 0-4.397 3.162-4.457 3.297a.503.503 0 00.755.605c.012-.009 1.262-.902 3.702-.902 2.426 0 3.674.881 3.702.901a.498.498 0 00.755-.604zM5.999 11A1 1 0 015.2 9.4C8.462 5.05 12.816 5 13 5a1 1 0 01.004 2c-.155.002-3.568.086-6.204 3.6a.998.998 0 01-.801.4zm23.002 3a.998.998 0 01-.801-.4c-2.592-3.456-6.961-2.628-7.004-2.62a.998.998 0 01-1.177-.784 1.001 1.001 0 01.784-1.177c.231-.047 5.657-1.072 8.996 3.38A1 1 0 0129.001 14z'
		fill='#664500'
	/>,
	<path
		d='M12.5 19c1.38 0 2.5-1.567 2.5-3.5S13.88 12 12.5 12 10 13.567 10 15.5s1.12 3.5 2.5 3.5zM23.5 19c1.38 0 2.5-1.567 2.5-3.5S24.88 12 23.5 12 21 13.567 21 15.5s1.12 3.5 2.5 3.5z'
		fill='#664500'
	/>,
	<path
		d='M6 13a1 1 0 01-.004-2c.156-.002 3.569-.086 6.205-3.6a1 1 0 011.6 1.2C10.538 12.95 6.184 13 6 13zm24 0c-.184 0-4.537-.05-7.8-4.4a1 1 0 111.599-1.2c2.641 3.521 6.061 3.599 6.206 3.6.55.006.994.456.991 1.005A.996.996 0 0130 13zm.6 7.2c-.114-.086-1.931-1.426-4.646-2.344.026-.115.046-.233.046-.356 0-.369-.139-.703-.359-.964A13.866 13.866 0 0129.002 16 1 1 0 0029 14c-.221 0-5.451.038-8.707 3.293A1 1 0 0021 19c4.59 0 8.363 2.772 8.401 2.801a1.003 1.003 0 001.4-.202A1 1 0 0030.6 20.2zm-14.893-2.907C12.452 14.038 7.221 14 7 14a1.001 1.001 0 00-.001 2c.026 0 1.558.016 3.361.536-.221.261-.36.595-.36.964 0 .123.019.241.047.356-2.716.918-4.533 2.258-4.647 2.344a.999.999 0 101.2 1.6c.037-.028 3.787-2.8 8.4-2.8a1 1 0 00.707-1.707z'
		fill='#664500'
	/>,
	<path
		d='M15.707 17.293C12.452 14.038 7.221 14 7 14a1.001 1.001 0 00-.001 2c.029 0 1.925.022 3.983.737-.593.64-.982 1.634-.982 2.763 0 1.934 1.119 3.5 2.5 3.5s2.5-1.566 2.5-3.5c0-.174-.019-.34-.037-.507.013 0 .025.007.037.007a.999.999 0 00.707-1.707zM29 14c-.221 0-5.451.038-8.707 3.293A.999.999 0 0021 19c.013 0 .024-.007.036-.007-.016.167-.036.333-.036.507 0 1.934 1.119 3.5 2.5 3.5s2.5-1.566 2.5-3.5c0-1.129-.389-2.123-.982-2.763A13.928 13.928 0 0129.002 16 1 1 0 0029 14z'
		fill='#664500'
	/>,
	<path
		d='M15.707 17.293C12.452 14.038 7.221 14 7 14a1.001 1.001 0 00-.001 2c.029 0 1.925.022 3.983.737-.593.64-.982 1.634-.982 2.763 0 1.934 1.119 3.5 2.5 3.5s2.5-1.566 2.5-3.5c0-.174-.019-.34-.037-.507.013 0 .025.007.037.007a.999.999 0 00.707-1.707zM29 14c-.221 0-5.451.038-8.707 3.293A.999.999 0 0021 19c.013 0 .024-.007.036-.007-.016.167-.036.333-.036.507 0 1.934 1.119 3.5 2.5 3.5s2.5-1.566 2.5-3.5c0-1.129-.389-2.123-.982-2.763A13.928 13.928 0 0129.002 16 1 1 0 0029 14z'
		fill='#292F33'
	/>,
	<path
		d='M11.5 20.5c1.38 0 2.5-1.567 2.5-3.5s-1.12-3.5-2.5-3.5S9 15.067 9 17s1.12 3.5 2.5 3.5zM24.5 20.5c1.38 0 2.5-1.567 2.5-3.5s-1.12-3.5-2.5-3.5S22 15.067 22 17s1.12 3.5 2.5 3.5zM5.999 13.5a1 1 0 01-.799-1.6c3.262-4.35 7.616-4.4 7.8-4.4a1 1 0 01.004 2c-.155.002-3.568.086-6.204 3.6a.998.998 0 01-.801.4zm24.002 0a.998.998 0 01-.801-.4c-2.641-3.521-6.061-3.599-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0123 7.5c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6z'
		fill='#664500'
	/>,
	<path
		d='M29 10c-5.554 0-7.802-4.367-7.895-4.553a1 1 0 011.787-.899C22.967 4.694 24.713 8 29 8a1 1 0 110 2zM7 10a1 1 0 010-2c5.083 0 5.996-3.12 6.033-3.253a1.007 1.007 0 011.219-.709c.53.139.851.673.718 1.205C14.921 5.437 13.704 10 7 10zm-.999 13a1 1 0 01-.601-1.799c.143-.107 2.951-2.183 6.856-2.933C9.781 17.027 7.034 17 6.999 17A1 1 0 017 15c.221 0 5.452.038 8.707 3.293A1 1 0 0115 20c-4.613 0-8.363 2.772-8.4 2.8a.996.996 0 01-.599.2zm23.998-.001a.998.998 0 01-.598-.198C29.363 22.772 25.59 20 21 20a.999.999 0 01-.707-1.707C23.549 15.038 28.779 15 29 15a1 1 0 01.002 2c-.036 0-2.783.027-5.258 1.268 3.905.75 6.713 2.825 6.855 2.933a1 1 0 01-.6 1.798z'
		fill='#664500'
	/>,
	<path
		d='M20.001 13a1 1 0 01-.987-1.165C19.407 9.471 21.584 5 27 5a1 1 0 110 2c-5.095 0-5.979 4.954-6.014 5.165a1 1 0 01-.985.835zm-4.002 0a1 1 0 01-.985-.833C14.977 11.957 14.059 7 9 7a1 1 0 010-2c5.416 0 7.592 4.471 7.986 6.835A1 1 0 0115.999 13zm-4.589 4.892c-2.335 0-3.87-.997-3.964-1.06a1 1 0 011.11-1.664c.099.064 2.602 1.636 5.998-.062a1 1 0 01.895 1.789c-1.495.746-2.868.997-4.039.997zm13.181 0c-1.172 0-2.545-.251-4.038-.998a1 1 0 01.895-1.789c3.4 1.7 5.899.126 6.004.059a1.004 1.004 0 011.382.289.997.997 0 01-.278 1.379c-.096.063-1.631 1.06-3.965 1.06z'
		fill='#664500'
	/>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM24.5 20c1.38 0 2.5-1.567 2.5-3.5S25.88 13 24.5 13 22 14.567 22 16.5s1.12 3.5 2.5 3.5zM30 13c-5.554 0-7.802-4.367-7.895-4.553a1 1 0 011.787-.899C23.967 7.694 25.713 11 30 11a1 1 0 110 2zM6 13a1 1 0 010-2c5.083 0 5.996-3.12 6.033-3.253.145-.528.69-.848 1.219-.709.53.139.851.673.718 1.205C13.921 8.437 12.704 13 6 13z'
		fill='#664500'
	/>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM24.5 20c1.38 0 2.5-1.567 2.5-3.5S25.88 13 24.5 13 22 14.567 22 16.5s1.12 3.5 2.5 3.5z'
		fill='#664500'
	/>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM24.5 20c1.38 0 2.5-1.567 2.5-3.5S25.88 13 24.5 13 22 14.567 22 16.5s1.12 3.5 2.5 3.5zM5.999 12a1 1 0 01-.799-1.6C8.462 6.05 12.816 6 13 6a1 1 0 01.004 2c-.155.002-3.568.086-6.204 3.6a.998.998 0 01-.801.4zm24.002 0a.998.998 0 01-.801-.4c-2.64-3.521-6.061-3.598-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0123 6c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6z'
		fill='#664500'
	/>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM24.5 20c1.38 0 2.5-1.567 2.5-3.5S25.88 13 24.5 13 22 14.567 22 16.5s1.12 3.5 2.5 3.5zM5.999 12a1 1 0 01-.799-1.6C8.462 6.05 12.816 6 13 6a1 1 0 01.004 2c-.155.002-3.568.086-6.204 3.6a.998.998 0 01-.801.4zm24.002 0a.998.998 0 01-.801-.4c-2.64-3.521-6.061-3.598-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0123 6c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6z'
		fill='#664500'
	/>,
	<path
		d='M16.664 10.543a.501.501 0 00-.592.148c-.009.012-.979 1.202-3.335 1.844a8.544 8.544 0 01-2.245.319c-.992 0-1.559-.212-1.562-.213a.499.499 0 00-.571.781c.071.087 1.78 2.147 4.125 2.147.351 0 .702-.047 1.043-.14 2.854-.778 3.41-4.208 3.432-4.354a.5.5 0 00-.295-.532zm10.975 2.155a.502.502 0 00-.59-.159c-.006.003-.633.256-1.737.256a8.705 8.705 0 01-2.073-.265c-2.369-.583-3.369-1.749-3.377-1.758a.5.5 0 00-.879.405c.026.145.668 3.56 3.539 4.267.311.077.629.116.946.116 2.396 0 4.099-2.16 4.17-2.252a.504.504 0 00.001-.61zM9.998 10a1 1 0 01-.245-1.969c.036-.009 3.593-.961 5.352-4.478a1 1 0 011.789.895c-2.181 4.361-6.471 5.477-6.652 5.523-.081.02-.163.029-.244.029zM26 10c-.08 0-.161-.01-.242-.03-.182-.045-4.472-1.162-6.652-5.523a1 1 0 011.789-.895c1.758 3.517 5.315 4.469 5.352 4.478.533.137.857.681.722 1.215A1.002 1.002 0 0126 10z'
		fill='#664500'
	/>,
	<>
		<path
			d='M24.5 22a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM11.5 22a5.5 5.5 0 100-11 5.5 5.5 0 000 11z'
			fill='#F5F8FA'
		/>
		<path
			d='M11.5 19a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM24.5 19a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM30.001 11a.998.998 0 01-.801-.4c-2.64-3.521-6.061-3.598-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0123 5c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6zM5.999 11A1 1 0 015.2 9.4C8.462 5.05 12.816 5 13 5a1 1 0 01.004 2c-.155.002-3.568.086-6.204 3.6a.998.998 0 01-.801.4z'
			fill='#664500'
		/>
	</>,
	<path
		d='M5.999 11A1 1 0 015.2 9.4C8.462 5.05 12.816 5 13 5a1 1 0 01.004 2c-.155.002-3.568.086-6.204 3.6a.998.998 0 01-.801.4zm24.002 0a.998.998 0 01-.801-.4c-2.64-3.521-6.061-3.598-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0123 5c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6zM12 18c1.38 0 2.5-1.567 2.5-3.5S13.38 11 12 11s-2.5 1.567-2.5 3.5S10.62 18 12 18zM24 18c1.38 0 2.5-1.567 2.5-3.5S25.38 11 24 11s-2.5 1.567-2.5 3.5S22.62 18 24 18z'
		fill='#664500'
	/>,
	<path
		d='M7.347 11.91c-.946 3.176.107 6.293 2.353 6.962 2.246.67 4.834-1.362 5.779-4.538.946-3.175-.106-6.293-2.351-6.962-2.246-.669-4.834 1.363-5.781 4.538zm21.305 0c.946 3.176-.107 6.293-2.352 6.962-2.246.67-4.834-1.362-5.779-4.538-.946-3.175.107-6.293 2.351-6.962 2.245-.669 4.833 1.363 5.78 4.538z'
		fill='#F5F8FA'
	/>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM24.5 20c1.38 0 2.5-1.567 2.5-3.5S25.88 13 24.5 13 22 14.567 22 16.5s1.12 3.5 2.5 3.5zM30 12c-5.554 0-7.802-4.367-7.895-4.553a1 1 0 011.787-.899C23.967 6.694 25.713 10 30 10a1 1 0 110 2zM6 12a1 1 0 010-2c5.083 0 5.996-3.12 6.033-3.253.145-.528.69-.848 1.219-.709.53.139.851.673.718 1.205C13.921 7.437 12.704 12 6 12z'
		fill='#664500'
	/>,
	<path
		d='M31.001 11a.998.998 0 01-.801-.4c-2.64-3.521-6.061-3.598-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0124 5c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6zM4.999 11A1 1 0 014.2 9.4C7.462 5.05 11.816 5 12 5a1 1 0 01.004 2c-.155.002-3.568.086-6.204 3.6a.998.998 0 01-.801.4zM12 18c1.38 0 2.5-1.567 2.5-3.5S13.38 11 12 11s-2.5 1.567-2.5 3.5S10.62 18 12 18zM24 18c1.38 0 2.5-1.567 2.5-3.5S25.38 11 24 11s-2.5 1.567-2.5 3.5S22.62 18 24 18z'
		fill='#664500'
	/>,
	<path
		d='M12 17c1.38 0 2.5-1.567 2.5-3.5S13.38 10 12 10s-2.5 1.567-2.5 3.5S10.62 17 12 17zM24 17c1.38 0 2.5-1.567 2.5-3.5S25.38 10 24 10s-2.5 1.567-2.5 3.5S22.62 17 24 17z'
		fill='#664500'
	/>,
	<path
		d='M30 15a.987.987 0 01-.371-.072c-5.229-2.091-7.372-5.241-7.461-5.374a1 1 0 011.662-1.113c.019.027 1.93 2.785 6.541 4.629A1 1 0 0130 15zM6 15a1.001 1.001 0 01-.372-1.929c4.612-1.844 6.523-4.602 6.542-4.629a1.002 1.002 0 011.387-.27.998.998 0 01.275 1.383c-.089.133-2.232 3.283-7.46 5.374A1.015 1.015 0 016 15zM14.999 18c-.15 0-.303-.034-.446-.105-3.512-1.756-7.07-.018-7.105 0a1 1 0 11-.895-1.789c.182-.09 4.498-2.197 8.895 0A1 1 0 0114.999 18zm14 0c-.15 0-.303-.034-.446-.105-3.513-1.756-7.07-.018-7.105 0a1 1 0 11-.895-1.789c.182-.09 4.501-2.196 8.895 0A1 1 0 0128.999 18z'
		fill='#664500'
	/>,
	<path
		d='M12 17c1.38 0 2.5-1.567 2.5-3.5S13.38 10 12 10s-2.5 1.567-2.5 3.5S10.62 17 12 17zM24 17c1.38 0 2.5-1.567 2.5-3.5S25.38 10 24 10s-2.5 1.567-2.5 3.5S22.62 17 24 17z'
		fill='#664500'
	/>,
	<path
		d='M29 10c-5.554 0-7.802-4.367-7.895-4.553a1 1 0 011.787-.899C22.967 4.694 24.713 8 29 8a1 1 0 110 2zM7 10a1 1 0 010-2c5.083 0 5.996-3.12 6.033-3.253a1.007 1.007 0 011.219-.709c.53.139.851.673.718 1.205C14.921 5.437 13.704 10 7 10zM6.001 19a1 1 0 01-.601-1.799c.143-.107 2.951-2.183 6.856-2.933C9.781 13.027 7.034 13 6.999 13A1.001 1.001 0 017 11c.221 0 5.452.038 8.707 3.293A1 1 0 0115 16c-4.613 0-8.363 2.772-8.4 2.8a.996.996 0 01-.599.2zm23.998-.001a.998.998 0 01-.598-.198C29.363 18.772 25.59 16 21 16a1 1 0 01-.707-1.707C23.549 11.038 28.779 11 29 11a1 1 0 01.002 2c-.036 0-2.783.027-5.258 1.268 3.905.75 6.713 2.826 6.855 2.933a1 1 0 01-.6 1.798z'
		fill='#664500'
	/>,
	<path
		d='M15.703 17.598c-.013.009-1.262.902-3.703.902-2.442 0-3.69-.893-3.7-.9a.499.499 0 00-.716.678c.061.09 1.515 2.222 4.416 2.222 2.9 0 4.355-2.132 4.416-2.223a.5.5 0 00-.713-.679zm12.642.042a.5.5 0 00-.643-.042c-.012.009-1.262.902-3.702.902-2.441 0-3.69-.893-3.7-.9a.499.499 0 00-.716.678c.061.09 1.515 2.222 4.416 2.222 2.9 0 4.355-2.132 4.416-2.223a.498.498 0 00-.071-.637zM31.001 16a.998.998 0 01-.801-.4c-2.641-3.521-6.061-3.599-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0124 10c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6zM4.999 16a1 1 0 01-.799-1.6C7.462 10.05 11.816 10 12 10a1 1 0 01.004 2c-.156.002-3.569.086-6.205 3.6a.995.995 0 01-.8.4z'
		fill='#664500'
	/>,
	<path
		d='M17.312 16.612a.501.501 0 00-.61-.014c-.012.009-1.26.902-3.702.902-2.441 0-3.69-.893-3.7-.9a.5.5 0 00-.757.603c.06.135 1.5 3.297 4.457 3.297 2.958 0 4.397-3.162 4.457-3.297a.498.498 0 00-.145-.591zm10 0a.5.5 0 00-.61-.014c-.012.009-1.261.902-3.702.902-2.44 0-3.69-.893-3.7-.9a.5.5 0 00-.757.603c.06.135 1.5 3.297 4.457 3.297 2.958 0 4.397-3.162 4.457-3.297a.498.498 0 00-.145-.591z'
		fill='#664500'
	/>,
	<path
		d='M5.999 11A1 1 0 015.2 9.4C8.462 5.05 12.816 5 13 5a1 1 0 01.004 2c-.155.002-3.568.086-6.204 3.6a.998.998 0 01-.801.4zm24.002 0a.998.998 0 01-.801-.4c-2.64-3.521-6.061-3.598-6.206-3.6a1.002 1.002 0 01-.991-1.005A.997.997 0 0123 5c.184 0 4.537.05 7.8 4.4a1 1 0 01-.799 1.6zm-16.087 4.5l1.793-1.793a.999.999 0 10-1.414-1.414L12.5 14.086l-1.793-1.793a.999.999 0 10-1.414 1.414l1.793 1.793-1.793 1.793a.999.999 0 101.414 1.414l1.793-1.793 1.793 1.793a.997.997 0 001.414 0 .999.999 0 000-1.414L13.914 15.5zm11 0l1.793-1.793a.999.999 0 10-1.414-1.414L23.5 14.086l-1.793-1.793a.999.999 0 10-1.414 1.414l1.793 1.793-1.793 1.793a.999.999 0 101.414 1.414l1.793-1.793 1.793 1.793a.997.997 0 001.414 0 .999.999 0 000-1.414L24.914 15.5z'
		fill='#664500'
	/>,
	<path
		d='M11.5 20c1.38 0 2.5-1.567 2.5-3.5S12.88 13 11.5 13 9 14.567 9 16.5s1.12 3.5 2.5 3.5zM24.5 20c1.38 0 2.5-1.567 2.5-3.5S25.88 13 24.5 13 22 14.567 22 16.5s1.12 3.5 2.5 3.5z'
		fill='#664500'
	/>,
	<path
		d='M7.001 15a1 1 0 01-.556-1.832C6.578 13.08 9.746 11 14 11a1 1 0 010 2c-3.655 0-6.418 1.814-6.445 1.832a.996.996 0 01-.554.168zm21.998 0a.996.996 0 01-.552-.167C28.419 14.815 25.628 13 22 13a1 1 0 110-2c4.254 0 7.422 2.08 7.555 2.168A1 1 0 0128.999 15z'
		fill='#664500'
	/>,
	<path
		d='M12 17c1.38 0 2.5-1.567 2.5-3.5S13.38 10 12 10s-2.5 1.567-2.5 3.5S10.62 17 12 17zM24 17c1.38 0 2.5-1.567 2.5-3.5S25.38 10 24 10s-2.5 1.567-2.5 3.5S22.62 17 24 17z'
		fill='#664500'
	/>,
	<>
		<path
			d='M24.5 19a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM11.5 19a5.5 5.5 0 100-11 5.5 5.5 0 000 11z'
			fill='#F4F7F9'
		/>
		<path
			d='M10.5 13a2.5 2.5 0 002.5-2.5c0-1.252-.923-2.28-2.124-2.462a5.441 5.441 0 00-2.178.736A2.487 2.487 0 008 10.5a2.5 2.5 0 002.5 2.5zm13 0a2.5 2.5 0 002.5-2.5c0-1.252-.923-2.28-2.124-2.462a5.437 5.437 0 00-2.177.736A2.484 2.484 0 0021 10.5a2.5 2.5 0 002.5 2.5z'
			fill='#65471B'
		/>
	</>,
	<>
		<path d='M24.5 19a4.5 4.5 0 100-9 4.5 4.5 0 000 9z' fill='#fff' />
		<path
			d='M24.5 17.221a2.721 2.721 0 100-5.442 2.721 2.721 0 000 5.442z'
			fill='#DD2E44'
		/>
		<path d='M11.5 19a4.5 4.5 0 100-9 4.5 4.5 0 000 9z' fill='#fff' />
		<path
			d='M11.5 17.221a2.721 2.721 0 100-5.442 2.721 2.721 0 000 5.442z'
			fill='#DD2E44'
		/>
	</>,
	<>
		<path
			d='M13.119 13.83c1.174 0 2.125-1.19 2.125-2.656 0-1.467-.951-2.656-2.125-2.656s-2.125 1.19-2.125 2.656c0 1.467.951 2.656 2.125 2.656zM24.375 14.892c1.174 0 2.125-1.19 2.125-2.656 0-1.467-.951-2.656-2.125-2.656s-2.125 1.19-2.125 2.656c0 1.467.951 2.656 2.125 2.656z'
			fill='#65471B'
		/>
		<path
			d='M9.296 6.351a.925.925 0 01-.391-.399.8.8 0 01.393-1.112c4.266-1.831 7.699-.043 7.843.034.433.231.608.747.391 1.154-.216.405-.74.546-1.173.318-.123-.063-2.832-1.432-6.278.047a.915.915 0 01-.785-.042zm12.135 3.75a.924.924 0 01-.362-.424.8.8 0 01.468-1.084c4.381-1.536 7.685.48 7.823.567.415.26.555.787.312 1.178-.242.39-.776.495-1.191.238-.12-.072-2.727-1.621-6.267-.379a.924.924 0 01-.783-.096z'
			fill='#65471B'
		/>
	</>,
	<path
		d='M12.5 20c1.38 0 2.5-1.567 2.5-3.5S13.88 13 12.5 13 10 14.567 10 16.5s1.12 3.5 2.5 3.5zM23.5 20c1.38 0 2.5-1.567 2.5-3.5S24.88 13 23.5 13 21 14.567 21 16.5s1.12 3.5 2.5 3.5zM29 12c-5.554 0-7.802-4.367-7.895-4.553a1 1 0 011.787-.899C22.967 6.694 24.713 10 29 10a1 1 0 110 2zm-22-.006a1 1 0 010-2c5.083 0 5.996-3.12 6.033-3.253.145-.528.69-.848 1.219-.709.53.139.851.673.718 1.205-.049.194-1.266 4.757-7.97 4.757z'
		fill='#664500'
	/>,
	<path
		d='M12.284 12.33c-.756-.292-1.471-.568-1.471-.968 0-.659.884-.693 1.061-.693.625 0 .936.234 1.21.441.21.159.428.323.731.323.564 0 .821-.397.821-.766 0-.736-.902-1.256-1.858-1.474V8.8a.94.94 0 00-1.878 0v.426c-1.144.333-1.845 1.174-1.845 2.235 0 1.311 1.293 1.849 2.434 2.323.817.34 1.589.661 1.589 1.162 0 .366-.46.762-1.203.762-.75 0-1.18-.3-1.56-.564-.262-.183-.51-.356-.806-.356-.43 0-.766.362-.766.824 0 .679.931 1.356 2.157 1.594v.47a.939.939 0 001.878 0l-.005-.498c1.296-.315 2.062-1.222 2.062-2.459.001-1.404-1.414-1.95-2.551-2.389zm14.41 2.406c0-1.404-1.415-1.95-2.552-2.389-.756-.292-1.47-.568-1.47-.968 0-.659.884-.693 1.061-.693.625 0 .935.234 1.21.441.211.159.428.323.73.323.565 0 .822-.397.822-.766 0-.737-.902-1.256-1.858-1.474v-.392a.94.94 0 00-1.879 0v.426c-1.143.333-1.845 1.174-1.845 2.235 0 1.311 1.293 1.849 2.434 2.323.817.34 1.589.661 1.589 1.162 0 .366-.459.762-1.202.762-.75 0-1.18-.3-1.559-.564-.263-.183-.511-.356-.806-.356-.431 0-.767.362-.767.824 0 .679.931 1.356 2.156 1.594v.47a.939.939 0 001.879 0l-.003-.498c1.295-.317 2.06-1.223 2.06-2.46z'
		fill='#664500'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M28.028 13.036c-.244-.3-6.771-1.502-6.826-1.502-.118 0-.251.089-.275.205a4.073 4.073 0 00.466 2.941c.521.876 1.337 1.47 2.296 1.673.232.049.471.074.707.074 1.746 0 3.296-1.336 3.686-3.177a.265.265 0 00-.054-.214zm-13.272-1.502c-.056 0-6.583 1.202-6.827 1.502a.257.257 0 00-.052.213c.389 1.84 1.939 3.176 3.686 3.176.236 0 .474-.025.707-.074 1.972-.417 3.211-2.486 2.762-4.613-.024-.115-.157-.204-.276-.204z'
				fill='#292F33'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M16 16.958c-.419 0-.809-.265-.949-.684-.203-.599-1.018-2.316-2.051-2.316-1.062 0-1.888 1.827-2.051 2.316a1 1 0 11-1.897-.633c.125-.377 1.304-3.684 3.949-3.684 2.645 0 3.823 3.307 3.949 3.684a1 1 0 01-.95 1.317zm10 0a.999.999 0 01-.948-.684c-.203-.599-1.019-2.316-2.052-2.316-1.062 0-1.889 1.827-2.052 2.316a1 1 0 01-1.897-.633c.125-.377 1.304-3.684 3.948-3.684s3.823 3.307 3.948 3.684A1 1 0 0126 16.958z'
		fill='#664500'
	/>,
	<path
		d='M11.016 6.08c-2.255.604-3.48 1.965-3.555 2.05a1.002 1.002 0 00.091 1.412.998.998 0 001.411-.091c.079-.087 2.09-2.253 5.81-1.492A1 1 0 1015.174 6c-1.635-.336-3.026-.223-4.158.08zm13.968.001c-1.132-.303-2.523-.416-4.159-.082a1 1 0 10.401 1.959c3.724-.762 5.731 1.405 5.814 1.497.367.407 1 .444 1.41.078a.997.997 0 00.088-1.404c-.075-.084-1.3-1.444-3.554-2.048z'
		fill='#664500'
	/>,
	<path
		d='M8.111 21.383a1 1 0 01-.845-1.533c.916-1.453 3.701-3.938 7.69-2.962a1 1 0 01-.476 1.942c-3.604-.882-5.502 2.056-5.521 2.086a1.001 1.001 0 01-.848.467zm11.973-3.742a1 1 0 01-.634-1.774c3.176-2.604 6.762-1.562 8.215-.646a1 1 0 01-1.064 1.694c-.138-.084-3.052-1.823-5.884.499a.992.992 0 01-.633.227z'
		fill='#664500'
	/>,
	<path
		d='M6.001 11a1 1 0 01-.004-2c.156-.002 3.569-.086 6.205-3.6a1 1 0 011.6 1.2C10.539 10.95 6.185 11 6.001 11zm24.986 2.393a1 1 0 01-1.945.468c-.038-.151-.911-3.452-4.941-5.201a1 1 0 01.796-1.834c4.989 2.165 6.047 6.388 6.09 6.567zM14.815 15.375c-.584 2.114-1.642 3.083-3.152 2.666-1.509-.417-2.343-1.909-1.76-4.023.583-2.112 2.175-3.363 3.684-2.946 1.511.417 1.812 2.19 1.228 4.303zm11.416-.755c.473 2.141-.675 4.838-2.204 5.176-1.529.338-3.28-1.719-3.753-3.86-.473-2.14.419-3.971 1.948-4.309 1.529-.338 3.536.853 4.009 2.993z'
		fill='#65471B'
	/>,
	<path
		d='M15.813 17.888a.501.501 0 01-.61.014C15.19 17.892 13.942 17 11.5 17c-2.441 0-3.69.893-3.7.9a.5.5 0 01-.757-.603C7.103 17.162 8.543 14 11.5 14c2.958 0 4.397 3.162 4.457 3.297a.499.499 0 01-.144.591zm12.999 0a.5.5 0 01-.61.014C28.19 17.892 26.941 17 24.5 17c-2.44 0-3.69.893-3.7.9a.5.5 0 01-.757-.603c.059-.135 1.5-3.297 4.457-3.297 2.958 0 4.398 3.162 4.457 3.297a.498.498 0 01-.145.591zM13.114 6.002A1 1 0 0113.041 8c-.156-.004-3.57-.052-6.339 3.357a1 1 0 01-1.552-1.262c3.428-4.219 7.78-4.1 7.964-4.093zm9.403 1.672c.181-.033 4.473-.763 8.461 2.933a.997.997 0 01.053 1.413.998.998 0 01-1.413.054c-3.228-2.992-6.608-2.456-6.75-2.432a.999.999 0 01-.351-1.968z'
		fill='#664500'
	/>,
	<path
		d='M11.471 13.529c0 1.706-.987 3.088-2.206 3.088-1.219 0-2.206-1.382-2.206-3.088 0-1.705.987-3.088 2.206-3.088 1.218 0 2.206 1.383 2.206 3.088zm11.529 0c0 1.706-.987 3.088-2.206 3.088-1.218 0-2.206-1.382-2.206-3.088 0-1.705.988-3.088 2.206-3.088 1.219 0 2.206 1.383 2.206 3.088z'
		fill='#664500'
	/>,
	<path
		d='M12 19.25c1.38 0 2.5-1.231 2.5-2.75s-1.12-2.75-2.5-2.75-2.5 1.231-2.5 2.75 1.12 2.75 2.5 2.75zM24 20c1.38 0 2.5-1.567 2.5-3.5S25.38 13 24 13s-2.5 1.567-2.5 3.5S22.62 20 24 20zM7.102 12.559a.996.996 0 00.885.135c4.187-1.355 7.027.554 7.146.636a1.003 1.003 0 001.39-.25.998.998 0 00-.241-1.388c-.15-.106-3.736-2.575-8.91-.901a.998.998 0 00-.27 1.768zm22.899-2.151c-.368 0-.731-.161-.979-.471-1.395-1.751-4.299-3.287-5.314-3.287-.642 0-1.925.911-2.604 1.568a1.251 1.251 0 01-1.743-1.794c.391-.38 2.436-2.275 4.347-2.275 2.005 0 5.538 2.056 7.27 4.229a1.25 1.25 0 01-.977 2.03z'
		fill='#664500'
	/>,
	<path
		d='M15.682 4.413l-4.542.801L8.8.961a1.252 1.252 0 00-2.331.411l-.745 4.797-4.542.801a1.25 1.25 0 00-.318 2.361l4.07 1.932-.748 4.812a1.253 1.253 0 001.235 1.442c.327 0 .65-.128.891-.372l3.512-3.561 4.518 2.145a1.25 1.25 0 001.631-1.731L13.625 9.73l3.165-3.208a1.252 1.252 0 00-1.108-2.109zm4.636 0l4.542.801L27.2.961a1.251 1.251 0 012.33.411l.745 4.797 4.542.801c.536.094.949.524 1.021 1.063a1.252 1.252 0 01-.703 1.297l-4.07 1.932.748 4.812a1.253 1.253 0 01-1.235 1.442c-.327 0-.65-.128-.891-.372l-3.512-3.561-4.518 2.145a1.25 1.25 0 01-1.631-1.731l2.348-4.267-3.165-3.208a1.252 1.252 0 01-.217-1.459 1.259 1.259 0 011.326-.65z'
		fill='#E95F28'
	/>,
	<>
		<g clipPath='url(#prefix__clip0)'>
			<path
				d='M29.284 9.012a6.734 6.734 0 11-13.169 2.821 6.734 6.734 0 0113.169-2.821z'
				fill='#F4F7F9'
			/>
			<path
				d='M22.306 11.328a2.037 2.037 0 100-4.074 2.037 2.037 0 000 4.074z'
				fill='#292F33'
			/>
			<path
				d='M14.088 14.282a3.938 3.938 0 11-7.7 1.65 3.938 3.938 0 017.7-1.65z'
				fill='#F4F7F9'
			/>
			<path
				d='M10.646 17.755a1.942 1.942 0 10-.814-3.798 1.942 1.942 0 00.814 3.798z'
				fill='#292F33'
			/>
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M14.222 13.222c0 1.391-.846 2.519-1.889 2.519-1.043 0-1.889-1.127-1.889-2.519 0-1.391.845-2.519 1.889-2.519 1.043.001 1.889 1.128 1.889 2.519zm15.664-2.951a1.061 1.061 0 00.849-1.7c-3.466-4.622-8.092-4.675-8.287-4.675a1.062 1.062 0 00-.004 2.125c.166.002 3.792.091 6.592 3.825.208.278.527.425.85.425zm-8.108 2.951c0 1.391.846 2.519 1.889 2.519 1.043 0 1.889-1.127 1.889-2.519 0-1.391-.845-2.519-1.889-2.519-1.044.001-1.889 1.128-1.889 2.519zM6.114 10.271a1.062 1.062 0 01-.849-1.7c3.466-4.622 8.092-4.675 8.287-4.675a1.062 1.062 0 01.004 2.125c-.166.002-3.792.091-6.592 3.825a1.06 1.06 0 01-.85.425z'
		fill='#65471B'
	/>,
	<>
		<path
			d='M30.335 12.068c-.903 2.745-3.485 4.715-6.494 4.715a6.782 6.782 0 01-4.385-1.598c.036.495.076.997.136 1.54.152 1.388.884 2.482 2.116 3.163.82.454 1.8.688 2.813.752 1.734.109 3.57-.28 4.873-.909 1.377-.665 2.272-1.862 2.456-3.285.183-1.415-.354-2.924-1.515-4.378z'
			fill='#fff'
		/>
		<path
			d='M21.351 7.583c-1.297.55-1.947 2.301-1.977 5.289l.039.068a5.339 5.339 0 004.429 2.343c2.634 0 4.849-1.937 5.253-4.524-.115-.105-.221-.212-.343-.316-3.715-3.17-6.467-3.257-7.401-2.86z'
			fill='#65471B'
		/>
		<path
			d='M23.841 16.783c3.009 0 5.591-1.97 6.494-4.715-.354-.443-.771-.88-1.241-1.309-.404 2.587-2.619 4.524-5.253 4.524a5.339 5.339 0 01-4.429-2.343l-.039-.068c-.007.701.021 1.473.083 2.313a6.782 6.782 0 004.385 1.598z'
			fill='#F4900C'
		/>
		<path
			d='M21.413 11.812a1.107 1.107 0 100-2.214 1.107 1.107 0 000 2.214zM12.159 16.783c-3.009 0-5.591-1.97-6.494-4.715-1.161 1.454-1.697 2.963-1.515 4.377.185 1.423 1.079 2.621 2.456 3.285 1.303.629 3.138 1.018 4.873.909 1.013-.064 1.993-.297 2.813-.752 1.231-.681 1.963-1.775 2.116-3.163.06-.542.1-1.042.136-1.536a6.795 6.795 0 01-4.385 1.595z'
			fill='#fff'
		/>
		<path
			d='M12.159 15.283a5.339 5.339 0 004.429-2.343l.039-.068c-.031-2.988-.68-4.739-1.977-5.289-.934-.397-3.687-.31-7.401 2.859-.122.104-.227.211-.343.316.404 2.588 2.619 4.525 5.253 4.525z'
			fill='#65471B'
		/>
		<path
			d='M16.626 12.872l-.039.068a5.339 5.339 0 01-4.429 2.343c-2.634 0-4.849-1.937-5.253-4.524-.47.429-.887.866-1.241 1.309.903 2.745 3.485 4.715 6.494 4.715a6.795 6.795 0 004.385-1.594c.062-.841.091-1.614.083-2.317z'
			fill='#F4900C'
		/>
		<path
			d='M9.781 11.81a1.106 1.106 0 10-.139-2.21 1.108 1.108 0 10.139 2.21z'
			fill='#fff'
		/>
	</>,
	<>
		<g clipPath='url(#prefix__clip0)' fill='#65471B'>
			<path d='M12.176 18.416c1.462 0 2.647-1.66 2.647-3.706 0-2.047-1.185-3.706-2.647-3.706-1.462 0-2.647 1.66-2.647 3.706 0 2.047 1.185 3.706 2.647 3.706zM14.825 9.946c-.322 0-.64-.146-.848-.423-.991-1.321-2.028-2.029-3.083-2.104-1.39-.095-2.523.947-2.734 1.158A1.057 1.057 0 116.663 7.08c.457-.457 2.129-1.936 4.381-1.773 1.695.12 3.251 1.111 4.627 2.945a1.059 1.059 0 01-.846 1.694zM23.707 18.122c-1.462 0-2.647-1.66-2.647-3.707 0-2.046 1.185-3.705 2.647-3.705 1.462 0 2.647 1.659 2.647 3.705 0 2.047-1.185 3.707-2.647 3.707zM21.058 9.651c.322 0 .64-.146.848-.423.99-1.32 2.028-2.029 3.083-2.104 1.39-.095 2.523.947 2.734 1.158a1.057 1.057 0 101.497-1.497c-.457-.457-2.13-1.936-4.381-1.773-1.695.12-3.251 1.111-4.627 2.945a1.059 1.059 0 00.846 1.694z' />
		</g>
		<defs>
			<clipPath id='prefix__clip0'>
				<path fill='#fff' d='M0 0h36v36H0z' />
			</clipPath>
		</defs>
	</>,
	<path
		d='M29 14a.997.997 0 01-.707-.293c-2.577-2.575-7.055-1.736-7.099-1.726a.996.996 0 01-1.174-.787.998.998 0 01.783-1.174c.227-.046 5.563-1.065 8.903 2.273A.999.999 0 0129 14zM7 14a.999.999 0 01-.707-1.707c3.34-3.341 8.678-2.318 8.903-2.273a1 1 0 01-.391 1.962c-.069-.015-4.529-.843-7.098 1.726A1 1 0 017 14zm19 5a.997.997 0 01-.707-.293c-1.304-1.305-4.09-.974-5.052-.736a1 1 0 01-.483-1.941c.186-.046 4.575-1.111 6.949 1.263A.999.999 0 0126 19zm-16 0a.999.999 0 01-.707-1.707c2.373-2.373 6.764-1.309 6.95-1.263a1 1 0 01-.483 1.941c-.964-.237-3.749-.567-5.052.736A1.001 1.001 0 0110 19z'
		fill='#664500'
	/>,
	<path
		d='M22.5 21a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM13.5 21a2.5 2.5 0 100-5 2.5 2.5 0 000 5z'
		fill='#662214'
	/>,
	<path
		d='M10 21a1 1 0 01-1-1v-2a1 1 0 012 0v2a1 1 0 01-1 1zm10 0a1 1 0 01-1-1v-2a1 1 0 112 0v2a1 1 0 01-1 1z'
		fill='#662113'
	/>,
	<path
		d='M12 23a1 1 0 01-1-1v-2a1 1 0 112 0v2a1 1 0 01-1 1zm12 0a1 1 0 01-1-1v-2a1 1 0 112 0v2a1 1 0 01-1 1z'
		fill='#662113'
	/>,
]

function Eyes({ name, index, ...props }) {
	return eyes[index]
}

export default Eyes
